import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { Column, Row } from 'Components/UI'

export const Container = styled(Row)``

export const AppName = styled.div`
  font-size: 24px;
  line-height: normal;
  font-weight: bold;
  color: ${themeGet('colors.white')};

  ${margin}
`

export const UserName = styled(Column)`
  margin-bottom: 2px;
  text-align: right;
  line-height: 1.2em;
  color: ${themeGet('colors.white')};
`
