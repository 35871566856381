import { useSubscription } from '@apollo/client'

import roomUpdateCreatedSubscription from 'GraphQL/Subscriptions/roomUpdateCreated.graphql'
import roomUpdateDeletedSubscription from 'GraphQL/Subscriptions/roomUpdateDeleted.graphql'
import roomUpdateUpdatedSubscription from 'GraphQL/Subscriptions/roomUpdateUpdated.graphql'
import {
  updateCreated,
  updateDeleted,
  updateUpdated,
} from 'GraphQL/Updaters/RoomUpdate'

import { useLocationSearchParams } from 'Hooks'

function RoomUpdateSubscriptionManager() {
  // TODO: NOT the best solution but needs to know roomId from somewhere cause
  // roomId is required param in subscriptions above
  const searchParams = useLocationSearchParams()
  const roomId = searchParams.get('id')

  useSubscription(roomUpdateCreatedSubscription, {
    shouldResubscribe: true,
    variables: { roomId },
    skip: !roomId,
    onSubscriptionData({ client, subscriptionData }) {
      if (!subscriptionData.data?.roomUpdateCreated) return

      updateCreated({
        client,
        roomUpdate: subscriptionData.data.roomUpdateCreated,
      })
    },
  })

  useSubscription(roomUpdateUpdatedSubscription, {
    shouldResubscribe: true,
    variables: { roomId },
    skip: !roomId,
    onSubscriptionData({ client, subscriptionData }) {
      if (!subscriptionData.data?.roomUpdateUpdated) return

      updateUpdated({
        client,
        roomUpdate: subscriptionData.data.roomUpdateUpdated,
      })
    },
  })

  useSubscription(roomUpdateDeletedSubscription, {
    shouldResubscribe: true,
    variables: { roomId },
    skip: !roomId,
    onSubscriptionData({ client, subscriptionData }) {
      if (!subscriptionData.data?.roomUpdateDeleted) return

      updateDeleted({
        client,
        roomUpdate: subscriptionData.data.roomUpdateDeleted,
      })
    },
  })

  return null
}

export default RoomUpdateSubscriptionManager
