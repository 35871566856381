import styled, { css } from 'styled-components'

import { Content as BaseContent } from '../styles'

const alignCenterCss = ({ alignCenter }) =>
  alignCenter &&
  css`
    text-align: center;
  `

export const Content = styled(BaseContent)`
  word-break: break-word;

  ${alignCenterCss}
`
