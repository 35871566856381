import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'

import { FiCheck, FiUpload } from 'react-icons/fi'

import { pick } from '@styled-system/props'

import { Row, Text } from 'Components/UI'

import { UPLOAD_TYPES } from 'Constants/ids'

import { uploadFile } from 'Services/Api/Queries/upload'
import _, { useScopedI18n } from 'Services/I18n'

import { fileToDataURL } from 'Utils/Files'

import { Container, Progress, Wrapper } from './styles'

function FileInput({
  disabled,
  value,
  entityId,
  uploadType,
  onChange,
  ...rest
}) {
  const s = useScopedI18n('dropzone')

  const [uploadingFile, setUploadingFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [percent, setPercent] = useState(0)

  const handleProgress = useCallback(event => setPercent(event?.percent), [])

  const handleUploadFile = useCallback(
    async file =>
      uploadFile({
        file,
        type: uploadType,
        entityId,
        onProgress: handleProgress,
      }),
    [entityId, handleProgress, uploadType],
  )

  const handleDrop = useCallback(
    async ([acceptedFile]) => {
      if (!acceptedFile) return

      setLoading(true)

      fileToDataURL(acceptedFile, setUploadingFile)

      const response = await handleUploadFile(acceptedFile)

      if (response?.ok) {
        onChange(response?.fileResource)
        setLoading(false)
        setUploadingFile(null)
        setPercent(0)
      }
    },
    [handleUploadFile, onChange],
  )

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: handleDrop,
    accept: '.pdf',
    disabled: loading || disabled,
  })

  const src = uploadingFile || value

  return (
    <Container {...pick(rest)}>
      <Wrapper
        {...getRootProps()}
        disabled={disabled}
        isDragActive={isDragActive}
        onClick={open}
      >
        <input {...getInputProps()} />

        {src ? (
          <>
            {!loading && (
              <Row center>
                <FiCheck size={36} strokeWidth={1} />
                <Text bold small>
                  {src?.name}
                </Text>
              </Row>
            )}
            {loading && <Progress value={percent} />}
          </>
        ) : (
          <>
            <FiUpload size={36} strokeWidth={1} />
            <Text alignTextCenter light mt={1} small>
              {s('dragNDrop')} {_('general.orLower')}
            </Text>
            <Text primary small>
              {s('select')}
            </Text>
          </>
        )}
      </Wrapper>
    </Container>
  )
}

FileInput.defaultProps = {
  disabled: false,
  entityId: null,
  height: 120,
  width: 120,
}

FileInput.propTypes = {
  disabled: PropTypes.bool,
  entityId: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  uploadType: PropTypes.oneOf(Object.values(UPLOAD_TYPES)).isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
}

export default FileInput
