import React from 'react'
import PropTypes from 'prop-types'

import styled, { ThemeProvider as BaseThemeProvider } from 'styled-components'

import useAppTheme from 'Hooks/useAppTheme'

import theme from './defaultTheme'

const Base = styled.div``

function ThemeProvider(props) {
  const themeByAppKind = useAppTheme()

  return (
    <BaseThemeProvider theme={{ ...themeByAppKind, ...props.theme }}>
      <Base {...props} />
    </BaseThemeProvider>
  )
}

ThemeProvider.defaultProps = {
  theme,
}

ThemeProvider.propTypes = {
  theme: PropTypes.shape({
    breakpoints: PropTypes.arrayOf(PropTypes.number),
    space: PropTypes.arrayOf(PropTypes.number),
    fontSizes: PropTypes.arrayOf(PropTypes.number),
    weights: PropTypes.arrayOf(PropTypes.number),
    colors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    font: PropTypes.string,
    monospace: PropTypes.string,
    radius: PropTypes.number,
  }),
}

export default ThemeProvider
