import loadable from '@loadable/component'

import Redirector from './Redirector'

const Public = {
  Redirector,
  Root: loadable(() => import('./Root' /* webpackChunkName: "public-root" */)),
  SocialCallback: loadable(() =>
    import('./SocialCallback' /* webpackChunkName: "public-social-callback" */),
  ),
  MobileRedirector: loadable(() =>
    import(
      './MobileRedirector' /* webpackChunkName: "public-mobile-redirector" */
    ),
  ),
  MobileRoomRedirector: loadable(() =>
    import(
      './MobileRoomRedirector' /* webpackChunkName: "public-room-redirector" */
    ),
  ),
}

export default Public
