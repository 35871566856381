export const ROLE_HASH = {
  ADMIN: 'admin',
  GUEST: 'guest',
  OPERATOR: 'operator',
}

export const INVITATION_STATE = {
  PENDING: 'pending',
}

export const INVITATION_KIND = {
  IDENTITY: 'identity',
  EMAIL: 'email',
  PERSONAL: 'personal',
  SMS: 'sms',
}

export const FOLDER_TYPE = {
  ARCHIVE: 'archive',
  DEFAULT: 'default',
}

export const UPDATE_KIND = {
  PLANNER: 'plannerTask',
  INFO_CENTER: 'organizationInfo',
  DEFAULT: 'default',
  ISSUE: 'issue',
  SIGNATURE: 'signature',
}

export const SORT_ORDER = {
  DESC: 'desc',
}

export const SORT_COLUMN = {
  LAST_OPEN: 'participation.lastTouchedAt',
  PINED_AT: 'participation.pinnedAt',
  CREATED_AT: 'participation.createdAt',
}

export const ACTION_HASH = {
  ACTIVE_USER_ROOM_REFETCH: 'activeUserRoomRefetch',
  ARCHIVE_USER_ROOM_REFETCH: 'archiveUserRoomRefetch',
}

export const ICON_KIND = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
}

export const ROOM_KIND = {
  UNIVERSAL: 'universal',
  PURPLE: 'purple',
}

export const UPDATE_ACTION_MODAL_OPTION = {
  CANCEL: 'CANCEL',
  DELETE: 'DELETE',
  SHARE: 'SHARE',
  EDIT: 'EDIT',
  PIN: 'PIN',
  UNPIN: 'UNPIN',
}

export const MESSAGE_ACTION_MODAL_OPTION = {
  CANCEL: 'CANCEL',
  DELETE: 'DELETE',
  REPLY: 'REPLY',
  EDIT: 'EDIT',
}

export const ROOM_PRIVACY = {
  PRIVATE: 'private',
  PUBLIC: 'public',
}

export const ROOM_MAX_FEATURE_BARS_AMOUNT = 100
