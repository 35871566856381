import { send, toObservable, unobserve } from '@absinthe/socket'
import { ApolloLink } from '@apollo/client'
import { print as printQuery } from 'graphql'

export function compose(...fns) {
  const len = fns.length - 1
  return x => {
    let y = x
    for (let i = len; i > -1; i -= 1) {
      y = fns[i].call(this, y)
    }
    return y
  }
}

const unobserveIfNeeded = (absintheSocket, notifier, observer) => {
  if (notifier && observer) {
    unobserve(absintheSocket, notifier, observer)
  }
}

const notifierToObservable = (absintheSocket, onError, onStart) => notifier =>
  toObservable(absintheSocket, notifier, {
    onError,
    onStart,
    unsubscribe: unobserveIfNeeded,
  })

const getRequest = ({ query, variables }) => ({
  operation: printQuery(query),
  variables,
})

/**
 * Creates a terminating ApolloLink to request operations using given
 * AbsintheSocket instance
 */
const createAbsintheSocketLink = (absintheSocket, onError, onStart) =>
  new ApolloLink(
    compose(
      notifierToObservable(absintheSocket, onError, onStart),
      request => send(absintheSocket, request),
      getRequest,
    ),
  )

export default createAbsintheSocketLink
