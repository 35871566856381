import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

const alignCenterCss = ({ alignCenter }) =>
  alignCenter &&
  css`
    align-items: center;
  `

const justifyCenterCss = ({ justifyCenter }) =>
  justifyCenter &&
  css`
    justify-content: center;
  `

const columnDirectionCss = ({ columnDirection }) =>
  columnDirection &&
  css`
    flex-direction: column;
  `

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100vw;
  height: 100vh;

  ${alignCenterCss}
  ${justifyCenterCss}
  ${columnDirectionCss}
`

Container.propTypes = {
  alignCenter: PropTypes.bool,
  columnDirection: PropTypes.bool,
  justifyCenterCss: PropTypes.bool,
}

Container.defaultProps = {
  alignCenter: false,
  columnDirection: false,
  justifyCenterCss: false,
}

export default Container
