import React, { useCallback, useEffect, useState } from 'react'
import { DateUtils } from 'react-day-picker'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import { Picker } from './styles'

import 'react-day-picker/lib/style.css'

function DateRangePicker({ value, timezone, onChange, ...rest }) {
  const { from: initialFrom, to: initialTo } = value

  const [from, setFrom] = useState(initialFrom ?? null)
  const [to, setTo] = useState(initialTo ?? null)
  const [enteredTo, setEnteredTo] = useState(initialTo ?? null)

  useEffect(() => {
    if (initialFrom !== from) {
      setTo(initialFrom)
    }
  }, [from, initialFrom, value])

  useEffect(() => {
    if (initialTo !== to) {
      setTo(initialTo)
      setEnteredTo(initialTo)
    }
  }, [initialTo, to])

  const handleDayClick = useCallback(
    (day, modifiers = {}) => {
      if (modifiers.disabled || !day) {
        return
      }

      if (from && to && day >= from && day <= to) {
        setFrom(null)
        setTo(null)
        setEnteredTo(null)
      } else if (isSelectingFirstDay(from, to, day)) {
        setFrom(day)
        setTo(null)
        setEnteredTo(null)
      } else {
        setFrom(from)
        setTo(day)
        setEnteredTo(day)

        onChange({ from, to: day })
      }
    },
    [from, to, onChange],
  )

  const handleDayEnter = useCallback(
    day => {
      if (isSelectingFirstDay(from, to, day)) {
        return
      }

      setEnteredTo(day)
    },
    [from, to],
  )

  return (
    <Picker
      modifiers={{ start: from, end: enteredTo }}
      selectedDays={[from, { from, to: enteredTo }]}
      {...rest}
      onDayClick={handleDayClick}
      onDayMouseEnter={handleDayEnter}
    />
  )
}

DateRangePicker.defaultProps = {
  value: {},
  timezone: 'local',
  onChange: noop,
}

DateRangePicker.propTypes = {
  ...Picker.propTypes,
  timezone: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
}

export default DateRangePicker

function isSelectingFirstDay(from, to, day) {
  const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from)
  const isRangeSelected = from && to
  return !from || isBeforeFirstDay || isRangeSelected
}
