import dcLogoImage from './dcLogo.png'
import defaultPurpleLogoImage from './defaultPurpleLogo.png'
import kapuaLogoImage from './kapuaLogo.png'
import sosLogoImage from './sosLogo.png'

const uwLogoImage = ''

export {
  dcLogoImage,
  defaultPurpleLogoImage,
  kapuaLogoImage,
  sosLogoImage,
  uwLogoImage,
}
