import React from 'react'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'

import { Link, Logo, Row } from 'Components/UI'

import { APP_NAME } from 'Config'

import { ROOT } from 'Router/routes'

import _ from 'Services/I18n'

import {
  BackgroundImage,
  Container,
  Content,
  Copyright,
  Info,
  Name,
  Text,
} from './styles'

function Auth({ route }) {
  return (
    <Container>
      <BackgroundImage />
      <Info>
        <Link clean to={ROOT}>
          <Row center>
            <Logo />
            <Name ml={12}>{APP_NAME}</Name>
          </Row>
        </Link>
        <Row mt={3}>
          <Text>{_('general.slogan')}</Text>
        </Row>
      </Info>
      <Content>
        {renderRoutes(route.routes)}
        <Copyright>{_('general.copyright', { appName: APP_NAME })}</Copyright>
      </Content>
    </Container>
  )
}

Auth.propTypes = {
  route: PropTypes.object.isRequired,
}

export default Auth
