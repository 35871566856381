import React from 'react'

import map from 'lodash/map'

import { Column } from 'Components/UI/Flex'
import Text from 'Components/UI/Text'

import { LONG_DASH } from 'Constants/strings'

export function selectOptions(options) {
  return map(options, option => {
    const { id, name } = option

    return {
      label: name,
      value: id,
    }
  })
}

export function selectUserOptions(options) {
  return map(options, option => {
    const { id } = option
    const name = `${option?.profile?.firstName} ${option?.profile?.lastName}`
    const email = option?.primaryEmailCredential?.email || LONG_DASH

    return {
      label: (
        <Column>
          <Text mb={1}>{name}</Text>
          <Text primary small>
            {email}
          </Text>
        </Column>
      ),
      value: id,
    }
  })
}
