import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import noop from 'lodash/noop'

import { Container, PickerInput } from './styles'

import { Picker } from '../DatePicker/styles'
import Popover from '../Popover'

function DateInput({
  value: initialValue,
  disabled,
  onChange,
  onBlur,
  ...rest
}) {
  const [visible, setVisible] = useState(false)

  const [value, setValue] = useState(initialValue ?? null)
  const [enteredTo, setEnteredTo] = useState(initialValue ?? null)

  useEffect(() => {
    if (initialValue !== value) {
      setValue(initialValue)
      setEnteredTo(initialValue)
    }
  }, [initialValue, value])

  const handleClickOutside = useCallback(() => {
    setVisible(false)
  }, [])

  const handleShowCalendar = useCallback(() => {
    setVisible(true)
  }, [])

  const handleDayMouseEnter = useCallback(day => {
    setEnteredTo(day)
  }, [])

  const handleValueChange = useCallback(
    (day, modifiers = {}) => {
      if (modifiers.disabled) {
        return
      }

      if (!day) {
        setValue(null)
        setEnteredTo(null)

        onChange(day)

        return
      }

      setValue(day)
      setEnteredTo(day)

      onChange(day)
    },
    [onChange],
  )

  return (
    <Popover
      blockProps={{ p: 0 }}
      content={
        <Picker
          border="none"
          modifiers={enteredTo}
          selectedDays={value}
          onDayClick={handleValueChange}
          onDayMouseEnter={handleDayMouseEnter}
        />
      }
      interactive
      visible={visible}
      onClickOutside={handleClickOutside}
      onHide={onBlur}
    >
      <Container disabled={disabled} {...pick(rest)}>
        <PickerInput
          inputProps={{ disabled }}
          value={value}
          onDayChange={handleValueChange}
          onDayPickerShow={handleShowCalendar}
        />
      </Container>
    </Popover>
  )
}

DateInput.defaultProps = {
  disabled: false,
  value: null,
  onChange: noop,
  onBlur: noop,
}

DateInput.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.instanceOf(Date),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}

export default DateInput
