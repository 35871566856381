import concat from 'lodash/concat'

import { CHAT_CHANNEL_KIND } from 'Constants/chat'

import channelsQuery from 'GraphQL/Queries/Chat/channels.graphql'
import { FragmentOptions } from 'GraphQL/Updaters/utils'

function channelCreated({ client, newChannel }) {
  if (!newChannel) return

  const options = {
    query: channelsQuery,
    variables: {
      closed: newChannel.closed,
      kinds: [CHAT_CHANNEL_KIND.DIRECT, CHAT_CHANNEL_KIND.GROUP],
      roomId: newChannel.roomId,
    },
    context: { chat: true },
  }

  const readData = client.readQuery(options)
  if (!readData) return

  client.writeQuery({
    ...options,
    data: {
      ...readData,
      channels: concat(readData.channels, newChannel),
    },
  })
}

function channelDeleted({ client, channel }) {
  if (!channel) return

  client.evict({ id: FragmentOptions.channel(channel.id).id })
}

function channelUnreadUpdated({ client, unreadCounter }) {
  if (!unreadCounter?.channelId) return

  const fragment = FragmentOptions.channel(unreadCounter.channelId)
  const data = client.readFragment(fragment)
  if (!data) return

  client.writeFragment({
    ...fragment,
    data: {
      ...data,
      unreadCount: unreadCounter.unreadCount,
      hasUnreadMessages: unreadCounter.hasUnreadMessages,
    },
  })
}

function channelReadMessages({ client, channelId }) {
  if (!channelId) return

  const fragment = FragmentOptions.channel(channelId)
  const data = client.readFragment(fragment)
  if (!data) return

  client.writeFragment({
    ...fragment,
    data: {
      ...data,
      hasUnreadMessages: false,
    },
  })
}

export {
  channelCreated,
  channelDeleted,
  channelReadMessages,
  channelUnreadUpdated,
}
