import styled, { css } from 'styled-components'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { Flex } from 'Components/UI'

const alternateWrapperCss = ({ alternate }) =>
  alternate &&
  css`
    background-color: ${themeGet('colors.white')};
    border: 1px dashed ${themeGet('colors.font.secondary')};

    > svg {
      color: ${themeGet('colors.secondaryBlack')};
    }
  `

const wrapperDisabledCss = ({ disabled }) =>
  disabled &&
  css`
    cursor: default;
  `

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${themeGet('space.3')}px;
  border-radius: max(6%, 4px);
  outline: none;
  cursor: pointer;
  overflow: hidden;
  border: 1px dashed ${themeGet('colors.border.default')};
  background-color: rgba(0, 0, 0, 0.05);
  > svg {
    color: ${themeGet('colors.font.secondary')};
  }
  ${props =>
    props.isDragActive &&
    css`
      border-color: ${themeGet('colors.positive')};
    `};
  ${layout.width}
  ${margin}

  ${alternateWrapperCss}
  ${wrapperDisabledCss}
`

const flexBlockCss = css`
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.4);
  z-index: 3;
  border-radius: 4px;
  :hover {
    color: ${themeGet('colors.primary')};
  }
`

export const DeleteButton = styled.div`
  ${flexBlockCss};
  right: 8px;
  top: 8px;
  width: 32px;
  height: 32px;
`

export const Replace = styled.div`
  ${flexBlockCss};
  padding: 8px;
  left: 50%;
  top: 50%;
  width: 42px;
  height: 42px;
  transform: translate(-50%, -50%);
`

export const Container = styled(Flex)`
  flex-wrap: wrap;
  position: relative;
  :hover {
    ${Replace},
    ${DeleteButton} {
      display: flex;
    }
  }
  ${layout.width};
  ${layout.height};
`

const secondaryImageCss = ({ secondary }) =>
  secondary &&
  css`
    height: 120px;
    width: 120px;
    object-fit: contain;
    position: static;
  `

const backgroundCss = ({ src }) => css`
  background: url('${src}');
  background-size: cover;
`
export const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${backgroundCss};
  ${secondaryImageCss}
`

const valueCss = ({ value }) => css`
  width: ${Math.max(Math.min(value || 0, 100), 0)}%;
`

const secondaryProgressCss = ({ secondary }) =>
  secondary &&
  css`
    height: 2px;
    bottom: 4%;
  `

export const Progress = styled.div`
  position: absolute;
  z-index: 3;
  bottom: 6%;
  left: 6%;
  right: 6%;
  width: 88%;
  height: max(4%, 4px);
  background: rgba(255, 255, 255, 0.4);
  border-radius: 9999px;
  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: ${themeGet('colors.primary')};
    border-radius: 9999px;
    width: 100%;
    transition: all 1s ease;
    ${valueCss};
  }

  ${secondaryProgressCss}
`

export const ButtonsRow = styled.div`
  display: flex;
  gap: ${themeGet('space.2')}px;

  ${margin}
`
