import styled, { css } from 'styled-components'

const multilineCss = ({ multiline, maxLines = 2 }) =>
  multiline &&
  css`
    display: -webkit-inline-box;
    -webkit-line-clamp: ${maxLines === Infinity ? 'none' : maxLines};
    -webkit-box-orient: vertical;
    white-space: pre-line;
  `

const TextOverflow = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${multilineCss}
`

export default TextOverflow
