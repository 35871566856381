import {
  APP_CONFIGURATION_APP_KIND,
  APP_CONFIGURATION_PLATFORM_TYPE,
} from 'Constants/appConfiguration'
import { LONG_DASH } from 'Constants/strings'

export const getChooseAppValue = appConfiguration =>
  `${appConfiguration?.appKind}-${appConfiguration?.platform}-${appConfiguration?.type}`

export const getChooseAppLabel = appConfiguration => {
  const appKindId = appConfiguration?.appKind
  const platformId = appConfiguration?.platform

  return `${APP_CONFIGURATION_APP_KIND[appKindId]?.label || LONG_DASH} ${
    APP_CONFIGURATION_PLATFORM_TYPE[platformId]?.label || LONG_DASH
  }`
}
