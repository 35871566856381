import { AuthLayout } from 'Components/Layout'

import { MOBILE_APP_KIND } from 'Constants/app'

import Auth from 'Pages/Auth'

import * as ROUTES from './routes'

//  Empty stands for DC too 👇
const CONFIRMATION_KINDS = [
  '',
  MOBILE_APP_KIND.DC,
  MOBILE_APP_KIND.SOS,
  MOBILE_APP_KIND.KAPUA,
  MOBILE_APP_KIND.UW,
]

export const AUTH_ROUTES = {
  component: AuthLayout,
  path: ROUTES.ROOT,
  routes: [
    {
      component: Auth.ChangePassword,
      path: ROUTES.AUTH_CHANGE_PASSWORD,
      exact: true,
    },
    {
      component: Auth.RequestChangePassword,
      path: ROUTES.AUTH_REQUEST_CHANGE_PASSWORD,
      exact: true,
    },
    {
      component: Auth.SignUp,
      path: ROUTES.AUTH_SIGN_UP,
      exact: true,
    },
    {
      component: Auth.Root,
      path: ROUTES.AUTH_ROOT,
      exact: true,
    },
    ...CONFIRMATION_KINDS.map(kind => ({
      component: Auth.ConfirmEmail,
      path: ROUTES.AUTH_CONFIRM_EMAIL(kind),
      exact: true,
    })),
  ],
}
