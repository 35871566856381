import React, { useMemo } from 'react'
import { useExpanded, useFlexLayout, useSortBy, useTable } from 'react-table'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import get from 'lodash/get'

import * as Cells from './Cells'
import {
  ArrowAsc,
  ArrowDesc,
  Body,
  Header,
  HeaderCell,
  RowCell,
  StyledTable,
  TableRow,
} from './styles'

function Table({
  columns,
  contentOffset,
  data,
  defaultColumn,
  headerSeparator,
  rowGap,
  rowHeight,
  sortable,
  ...rest
}) {
  const innerDefaultColumn = useMemo(() => defaultColumn, [defaultColumn])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn: innerDefaultColumn,
        disableSortBy: !sortable,
      },
      useSortBy,
      useFlexLayout,
      useExpanded,
    )

  return (
    <StyledTable {...getTableProps()} {...pick(rest)}>
      <Header contentOffset={contentOffset}>
        {headerGroups.map(headerGroup => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <HeaderCell
                {...column.getHeaderProps(column.getSortByToggleProps())}
                right={get(column, 'headerRight')}
              >
                {column.render('Header')}
                {sortable &&
                  column.isSorted &&
                  (column.isSortedDesc ? (
                    <ArrowDesc ml="2px" />
                  ) : (
                    <ArrowAsc ml="2px" />
                  ))}
              </HeaderCell>
            ))}
          </TableRow>
        ))}
      </Header>
      {headerSeparator}
      <Body {...getTableBodyProps()} rowGap={rowGap}>
        {rows.map(row => {
          prepareRow(row)

          return (
            <TableRow {...row.getRowProps()} height={rowHeight}>
              {row.cells.map(cell => (
                <RowCell
                  {...cell.getCellProps()}
                  right={get(cell, ['column', 'cellRight'])}
                >
                  {cell.render('Cell')}
                </RowCell>
              ))}
            </TableRow>
          )
        })}
      </Body>
    </StyledTable>
  )
}

Table.defaultProps = {
  contentOffset: undefined,
  defaultColumn: {
    minWidth: 30,
    width: 150,
    maxWidth: 200,
  },
  headerSeparator: null,
  rowGap: undefined,
  rowHeight: undefined,
  sortable: false,
}

Table.propTypes = {
  columns: PropTypes.any.isRequired,
  contentOffset: PropTypes.number,
  data: PropTypes.any.isRequired,
  defaultColumn: PropTypes.shape({
    minWidth: PropTypes.number,
    width: PropTypes.number,
    maxWidth: PropTypes.number,
  }),
  headerSeparator: PropTypes.element,
  rowGap: PropTypes.number,
  rowHeight: PropTypes.number,
  sortable: PropTypes.bool,
}

Table.Cells = Cells

export default Table
