import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { FiXCircle } from 'react-icons/fi'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { layout } from '@styled-system/layout'
import { omit, pick } from '@styled-system/props'
import { space } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { ErrorWrapper, Wrapper } from '../styles'

const Label = styled.div`
  position: absolute;
  color: ${themeGet('colors.font.secondary')};
  z-index: 2;
  padding-left: ${themeGet('space.3')}px;
  transition: all ease-in-out ${themeGet('transitionTime.default')};
  user-select: none;
  cursor: text;
`

const Close = styled.div`
  position: absolute;
  cursor: pointer;
  right: 8px;
  line-height: 0;
`

const StyledInput = styled.input`
  position: relative;
  width: 100%;
  height: ${mapToTheme('inputs.height')}px;
  margin: 0;
  padding: 0 ${themeGet('space.3')}px;
  border: none;
  outline: none;
  appearance: none;
  background: none;
  ::placeholder {
    color: ${themeGet('colors.font.secondary')};
  }
`

const labelTopCss = css`
  ${Label} {
    font-size: 12px;
    transform: translateY(-10px);
  }
`

const inputBottomCss = css`
  padding-top: 12px;
`

const withTypeCss = ({ withType }) => css`
  ${withType === 'date' && labelTopCss}
`

const withLabelCss = ({ withLabel }) =>
  withLabel &&
  css`
    :focus-within {
      ${labelTopCss}
    }
    > input {
      :focus {
        ${inputBottomCss}
      }
    }
    > input[type='date'] {
      ${inputBottomCss}
    }
  `

const hasValueCss = ({ hasValue }) =>
  hasValue &&
  css`
    ${StyledInput} {
      margin-right: 32px;
    }
  `

const hasValueAndLabelCss = ({ hasValue, withLabel }) =>
  hasValue &&
  withLabel &&
  css`
    ${labelTopCss}
    > input {
      ${inputBottomCss}
    }
  `

const withIconCss = ({ withIcon }) =>
  withIcon &&
  css`
    > svg:first-of-type {
      margin-left: 16px;
      margin-right: 0;
      color: ${mapToTheme('inputs.border')};
      width: 20px;
      height: 20px;
    }
  `

const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  background-color: ${mapToTheme('inputs.background')};
  border: solid 1px ${mapToTheme('inputs.border')};
  border-radius: 12px;
  outline: none;
  appearance: none;
  transition: all ease-in-out ${themeGet('transitionTime.default')};
  overflow: hidden;
  :focus-within {
    border-color: ${mapToTheme('inputs.focus.border')};
  }
  ${withLabelCss}
  ${withIconCss}
  ${hasValueCss}
  ${hasValueAndLabelCss}
  ${withTypeCss}
  ${space}
  ${layout}
`

export const TextArea = styled(StyledInput).attrs({
  as: 'textarea',
})`
  min-height: ${mapToTheme('inputs.height')}px;
  height: 100%;
  padding: ${themeGet('space.3')}px ${themeGet('space.3')}px;
  background-color: ${mapToTheme('inputs.background')};
  border: solid 1px ${mapToTheme('inputs.border')};
  border-radius: 10px;
  outline: none;
  appearance: none;
  transition: all ease-in-out ${themeGet('transitionTime.default')};
  resize: none;

  ${space}
  ${layout}
`

function Input({
  defaultValue,
  disabled,
  error,
  icon,
  label,
  value,
  onChange,
  onClear,
  ...rest
}) {
  const inputRef = useRef(null)
  const [innerValue, setInnerValue] = useState(defaultValue)
  const styledProps = pick(rest)
  const inputProps = omit(rest)

  const hasValue = !!value || !!innerValue

  const handleChange = onChange || (({ target }) => setInnerValue(target.value))

  const handleClear = useCallback(() => {
    if (inputRef.current) inputRef.current.value = ''
    setInnerValue('')

    if (typeof onClear === 'function') onClear()
  }, [onClear])

  const handleFocus = useCallback(() => inputRef.current?.focus(), [])

  return (
    <Wrapper {...styledProps}>
      <Container
        disabled={disabled}
        hasValue={hasValue}
        withError={!!error}
        withIcon={!!icon}
        withLabel={!!label}
        withType={rest.type}
      >
        {icon}

        <StyledInput
          ref={inputRef}
          {...inputProps}
          disabled={disabled}
          value={value || innerValue}
          onChange={handleChange}
        />

        {label && <Label onClick={handleFocus}>{label}</Label>}

        {hasValue && !disabled && (
          <Close onClick={handleClear}>
            <FiXCircle size={16} />
          </Close>
        )}
      </Container>

      {error && <ErrorWrapper>{error}</ErrorWrapper>}
    </Wrapper>
  )
}

Input.defaultProps = {
  autoComplete: 'on',
  autoFocus: false,
  defaultValue: null,
  disabled: false,
  error: false,
  icon: null,
  label: null,
  placeholder: '',
  small: false,
  type: 'text',
  value: undefined,
  onChange: null,
  onClear: null,
}

Input.propTypes = {
  autoComplete: PropTypes.oneOf(['on', 'off']),
  autoFocus: PropTypes.bool,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  icon: PropTypes.node,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  small: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
}

export default Input
