import { NavLink } from 'react-router-dom'

import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

export const Items = styled.div`
  min-width: 120px;
  background: ${themeGet('colors.white')};
  box-shadow: ${themeGet('shadow')};
  border-radius: 4px;
  overflow: hidden;
`

const Item = styled.div`
  height: 32px;
  display: flex;
  flex: 1 0;
  width: 100%;
  cursor: pointer;
  background: ${themeGet('colors.white')};
  align-items: center;
  padding: 0 8px;

  border-bottom: 1px solid ${themeGet('colors.border.default')};

  svg {
    margin-right: 4px;
  }

  :hover,
  :focus {
    background: ${themeGet('colors.bg.light')};
    color: ${themeGet('colors.primary')};
  }

  :last-child {
    border-bottom: 0;
  }
`

export const ItemLink = styled(Item).attrs({
  as: NavLink,
})`
  text-decoration: none;
  color: ${themeGet('colors.font.default')};
`

export const ItemButton = styled(Item)``
