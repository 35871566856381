import { useMemo } from 'react'

import { APP_KIND } from 'Constants/app'

import { useAppKind } from 'Services/Store/appKind'

import themes from 'Theme/themes'

export default function useAppTheme() {
  const { appKind } = useAppKind()

  return useMemo(() => {
    switch (appKind) {
      case APP_KIND.ADMIN:
        return themes.main
      case APP_KIND.ADVOCATE:
        return themes.portal
      default:
        return themes.defaultTheme
    }
  }, [appKind])
}
