import { FiCheck } from 'react-icons/fi'

import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'
import { typography } from '@styled-system/typography'

// TODO: map to theme
export const Icon = styled(FiCheck)`
  display: none;
  position: absolute;
  width: 14px;
  height: 14px;
  stroke-width: 3px;
  color: ${themeGet('colors.white')};
  left: 3px;
  top: 3px;
  pointer-events: none;
`

export const Background = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  background-color: ${themeGet('colors.bg.primary')};
  border: 1px solid ${themeGet('colors.primary')};
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
`

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2px;

  ${margin}
`

export const Container = styled.label`
  --size: 20px;

  position: relative;
  display: flex;
  align-items: flex-start;
  width: fit-content;
  min-height: var(--size);
  user-select: none;

  > input {
    width: var(--size);
    height: var(--size);
    margin: 0;
    opacity: 0;
    cursor: pointer;

    &:checked ~ ${Icon} {
      display: block;
    }

    &:checked ~ ${Background} {
      background-color: ${themeGet('colors.primary')};
    }

    &:disabled ~ ${Background} {
      border-color: ${themeGet('colors.border.default')};
      background: ${themeGet('colors.border.default')};
      cursor: not-allowed;
    }

    &:disabled ~ ${TextContent} p:first-child {
      color: ${themeGet('colors.font.secondary')};
      cursor: not-allowed;
    }
  }

  ${typography}
  ${margin}
`
