import loadable from '@loadable/component'

const Admin = {
  Root: loadable(() => import('./Root' /* webpackChunkName: "admin-root" */)),
  Admins: loadable(() =>
    import('./Admins' /* webpackChunkName: "admin-admins" */),
  ),
  Users: loadable(() =>
    import('./Users' /* webpackChunkName: "admin-users" */),
  ),
  UserProfile: loadable(() =>
    import('./UserProfile' /* webpackChunkName: "admin-user-profile" */),
  ),
  RoomSpecs: loadable(() =>
    import('./RoomSpecs' /* webpackChunkName: "admin-room-specs" */),
  ),
  RoomSpecProfile: loadable(() =>
    import(
      './RoomSpecProfile' /* webpackChunkName: "admin-room-spec-profile" */
    ),
  ),
  Organizations: loadable(() =>
    import('./Organizations' /* webpackChunkName: "admin-organizations" */),
  ),
  OrganizationProfile: loadable(() =>
    import(
      './OrganizationProfile' /* webpackChunkName: "admin-organization-profile" */
    ),
  ),
  Rooms: loadable(
    () => import('./Rooms') /* webpackChunkName: "admin-rooms" */,
  ),
  Dashboards: loadable(
    () => import('./Dashboards') /* webpackChunkName: "admin-dashboard" */,
  ),
  DashboardProfile: loadable(
    () =>
      import(
        './DashboardProfile'
      ) /* webpackChunkName: "admin-dashboard-profile" */,
  ),
  Directories: loadable(
    () => import('./Directories') /* webpackChunkName: "admin-directories" */,
  ),
  DirectoryProfile: loadable(
    () =>
      import(
        './DirectoryProfile'
      ) /* webpackChunkName: "admin-directory-profile" */,
  ),
  RoomTemplates: loadable(
    () => import('./RoomTemplates') /* webpackChunkName: "admin-templates" */,
  ),
  RoomTemplateProfile: loadable(
    () =>
      import(
        './RoomTemplateProfile'
      ) /* webpackChunkName: "admin-template-profile" */,
  ),
  ContacProfile: loadable(
    () =>
      import(
        './ContactProfile'
      ) /* webpackChunkName: "admin-contact-profile" */,
  ),
  UpdateApp: loadable(
    () => import('./UpdateApp') /* webpackChunkName: "admin-update-app" */,
  ),
  CreateUpdateAppConfigurations: loadable(
    () =>
      import(
        './UpdateApp/CreateUpdateAppConfigurations'
      ) /* webpackChunkName: "admin-update-app-configurations" */,
  ),
}

export default Admin
