import palette from './palette'

const text = {
  fontSize: {
    big: 24,
    middle: 16,
    small: 12,
    default: 14,
  },
  lineHeight: {
    big: 26,
    middle: 19,
    small: 14,
    default: 16,
  },
  fontWeight: {
    bolder: 900,
    bold: 600,
    default: 'normal',
  },
  color: {
    white: palette.white,
    positive: palette.positive,
    negative: palette.negative,
    neutral: palette.smokeDark,
    light: palette.gray,
    primary: palette.primary,
    default: palette.grayDark,
  },
}

export default text
