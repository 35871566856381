import numeral from 'numeral'

export function formatWithSpaces(value, format = '0,0') {
  return numeral(value).format(format).replace(/,/g, ' ')
}

export function iterativeAverage(value, count, add) {
  return (value * count + add) / (count + 1)
}

export function weightedIterativeAverage(value, weight, add, addWeight) {
  return (value * weight + add * addWeight) / (weight + addWeight)
}
