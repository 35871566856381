import palette from './palette'

const switches = {
  border: {
    default: palette.gray,
    disabled: palette.gray,
  },
  bg: {
    default: palette.white,
    disabled: palette.white,
    checked: palette.white,
  },
  icon: {
    bg: {
      default: palette.primaryDark,
      disabled: palette.gray,
    },
  },
}

export default switches
