import { NavLink } from 'react-router-dom'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

const cleanCss = ({ clean }) =>
  clean &&
  css`
    &:hover {
      text-decoration: none;
    }
  `

export default styled(NavLink)`
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  color: ${themeGet('colors.primary')};

  font-size: ${mapToTheme('text.fontSize')}px;
  font-weight: ${mapToTheme('text.fontWeight')};

  &:visited,
  &:focus,
  &:active {
    color: ${themeGet('colors.font.active')};
  }

  &:hover {
    text-decoration: underline;
  }

  ${cleanCss}
  ${layout.width}
  ${layout.height}
  ${margin}
`
