import { useCallback, useState } from 'react'

import { NetworkStatus, useQuery } from '@apollo/client'

import head from 'lodash/head'

import { MAX_LIMIT } from 'Constants/query'

function usePagedQuery(query, variables, options) {
  const [page, setPage] = useState(0)

  const changePage = useCallback(pageNumber => {
    setPage(pageNumber)
  }, [])

  const {
    data,
    networkStatus,
    loading,
    fetchMore: fetchMoreQuery,
    refetch: queryRefetch,
  } = useQuery(query, {
    variables: {
      ...variables,
      page,
      limit: variables?.limit ?? MAX_LIMIT,
    },
    ...options,
    fetchPolicy: 'cache-and-network',
  })

  const fetchMore = useCallback(() => {
    const queryName = head(Object.keys(data ?? {}))

    if (
      // skip pagination if current page is last query page
      (queryName && data?.[queryName]?.pages === page) ||
      // --
      networkStatus === NetworkStatus.fetchMore ||
      networkStatus === NetworkStatus.loading
    ) {
      return
    }

    setPage(page + 1)
    fetchMoreQuery({
      variables: {
        page: page + 1,
      },
    }).then()
  }, [data, networkStatus, fetchMoreQuery, page])

  const refetch = useCallback(
    async refetchVariables => {
      setPage(0)
      await queryRefetch?.(refetchVariables)
    },
    [queryRefetch],
  )

  return { data, changePage, networkStatus, loading, fetchMore, refetch, page }
}

export default usePagedQuery
