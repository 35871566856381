import ChannelFragment from 'GraphQL/Fragments/Chat/Channel.graphql'
import RoomFragment from 'GraphQL/Fragments/Room.graphql'
import RoomUpdateFragment from 'GraphQL/Fragments/RoomUpdate.graphql'

const FragmentOptions = {
  channel: id => ({
    id: `ChatChannel:${id}`,
    fragmentName: 'ChannelFields',
    fragment: ChannelFragment,
  }),
  roomUpdate: id => ({
    id: `RoomUpdate:${id}`,
    fragmentName: 'RoomUpdateFields',
    fragment: RoomUpdateFragment,
  }),
  room: id => ({
    id: `Room:${id}`,
    fragmentName: 'RoomFields',
    fragment: RoomFragment,
  }),
}

export { FragmentOptions }
