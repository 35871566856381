const APOLLO_CLIENT = 'apolloClient'
const CHAT_WEB_SOCKET_LINK = 'chatWebSocketLink'
const WEB_SOCKET_LINK = 'webSocketLink'
const STORE = 'store'

const services = {}

function checkHasValue(serviceName) {
  if (!services[serviceName])
    throw new Error(`${serviceName} not found in Shared`)

  return services[serviceName]
}

function setApolloClient(apolloClient) {
  services[APOLLO_CLIENT] = apolloClient
}

function setStore(store) {
  services[STORE] = store
}

function setWebSocketLink(webSocketLink) {
  services[WEB_SOCKET_LINK] = webSocketLink
}

function setChatWebSocketLink(chatWebSocketLink) {
  services[CHAT_WEB_SOCKET_LINK] = chatWebSocketLink
}

function getApolloClient() {
  return checkHasValue(APOLLO_CLIENT)
}

function getStore() {
  return checkHasValue(STORE)
}

function getWebSocketLink() {
  return checkHasValue(WEB_SOCKET_LINK)
}

function getChatWebSocketLink() {
  checkHasValue(CHAT_WEB_SOCKET_LINK)
}

export {
  getApolloClient,
  getChatWebSocketLink,
  getStore,
  getWebSocketLink,
  setApolloClient,
  setChatWebSocketLink,
  setStore,
  setWebSocketLink,
}
