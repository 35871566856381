import { useSubscription } from '@apollo/client'

import map from 'lodash/map'
import reject from 'lodash/reject'
import unionBy from 'lodash/unionBy'

import { MAX_LIMIT } from 'Constants/query'

import moreMessagesQuery from 'GraphQL/Queries/Chat/moreMessages.graphql'
import messageCreatedSubscription from 'GraphQL/Subscriptions/Chat/messageCreated.graphql'
import messageDeletedSubscription from 'GraphQL/Subscriptions/Chat/messageDeleted.graphql'
import messageEditedSubscription from 'GraphQL/Subscriptions/Chat/messageEdited.graphql'

const messageEdited = ({ client, message }) => {
  const query = {
    query: moreMessagesQuery,
    variables: {
      channelId: message.channel.id,
      first: MAX_LIMIT,
    },
  }

  const data = client.readQuery(query)

  if (!data) {
    return
  }

  const messages = map(data.moreMessages.messages, messageItem =>
    messageItem.id === message.id ? message : messageItem,
  )

  client.writeQuery({
    ...query,
    data: {
      ...data,
      moreMessages: { ...data.moreMessages, messages },
    },
  })
}

const messageDeleted = ({ client, message }) => {
  const query = {
    query: moreMessagesQuery,
    variables: {
      channelId: message.channel.id,
      first: MAX_LIMIT,
    },
  }

  const data = client.readQuery(query)

  if (!data) {
    return
  }

  const messages = reject(data.moreMessages.messages, {
    id: message.id,
  })

  client.writeQuery({
    ...query,
    data: {
      ...data,
      moreMessages: { ...data.moreMessages, messages },
    },
  })
}

const messageCreated = ({ client, message }) => {
  const query = {
    query: moreMessagesQuery,
    variables: {
      channelId: message.channel.id,
      first: MAX_LIMIT,
    },
  }

  const data = client.readQuery(query)

  if (!data) {
    return
  }

  const messages = unionBy([message], data.moreMessages.messages, 'id')

  client.writeQuery({
    ...query,
    data: {
      ...data,
      moreMessages: { ...data.moreMessages, messages },
    },
  })
}

function ChatMessageSubscriptionManager() {
  useSubscription(messageCreatedSubscription, {
    shouldResubscribe: true,
    onSubscriptionData({ client, subscriptionData }) {
      if (!subscriptionData.data?.messageCreated) return

      messageCreated({ client, message: subscriptionData.data.messageCreated })
    },
  })

  useSubscription(messageDeletedSubscription, {
    shouldResubscribe: true,
    onSubscriptionData({ client, subscriptionData }) {
      if (!subscriptionData.data?.messageDeleted) return

      messageDeleted({ client, message: subscriptionData.data.messageDeleted })
    },
  })

  useSubscription(messageEditedSubscription, {
    shouldResubscribe: true,
    onSubscriptionData({ client, subscriptionData }) {
      if (!subscriptionData.data?.messageEdited) return

      messageEdited({ client, message: subscriptionData.data.messageEdited })
    },
  })

  return null
}

export default ChatMessageSubscriptionManager
