import React from 'react'
import PropTypes from 'prop-types'

import { Container, Dot } from './styles'

import Text from '../Text'

function Toast({ title, text, toastProps }) {
  return (
    <Container>
      <Dot type={toastProps?.type || 'info'} />
      <Text bolder fontSize={16}>
        {title || 'Please set title!'}
      </Text>
      <Text small>{text || 'Please set text!'}</Text>
    </Container>
  )
}

Toast.defaultProps = {
  title: null,
  toastProps: {},
}

Toast.propTypes = {
  text: PropTypes.node.isRequired,
  title: PropTypes.node,
  toastProps: PropTypes.object,
}

export default Toast
