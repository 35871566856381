import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import Switch from 'Components/UI/Switch'

import Utils from 'Utils'

import { Label } from '../styles'

function SwitchField({
  checkErrorIfDirty,
  displayError,
  input,
  name,
  disabled,
  ...rest
}) {
  return (
    <Field name={name} type="checkbox">
      {({ input: { checked, onChange: handleChange }, meta }) => {
        const { hasError, error } = Utils.Form.hasError(meta, checkErrorIfDirty)

        return (
          <>
            <Switch
              checked={checked}
              disabled={disabled}
              error={!!hasError}
              {...input}
              {...pick(rest)}
              onChange={handleChange}
            />
            {displayError && hasError && (
              <Label error mt={1}>
                {error}
              </Label>
            )}
          </>
        )
      }}
    </Field>
  )
}

SwitchField.defaultProps = {
  checkErrorIfDirty: false,
  disabled: false,
  displayError: true,
  input: null,
}

SwitchField.propTypes = {
  checkErrorIfDirty: PropTypes.bool,
  disabled: PropTypes.bool,
  displayError: PropTypes.bool,
  input: PropTypes.object,
  name: PropTypes.string.isRequired,
}

export default SwitchField
