import concat from 'lodash/concat'
import merge from 'lodash/merge'

import getRoomUpdatesByRoomQuery from 'GraphQL/Queries/getRoomUpdatesByRoomId.graphql'
import { FragmentOptions } from 'GraphQL/Updaters/utils'

import Utils from 'Utils'

function updateCreated({ client, roomUpdate }) {
  if (!roomUpdate) return

  const options = {
    query: getRoomUpdatesByRoomQuery,
    variables: Utils.Query.getDefaultListVariables({
      roomId: roomUpdate.room?.id,
    }),
    skip: !roomUpdate.room?.id,
  }

  const readData = client.readQuery(options)
  if (!readData) return

  client.writeQuery({
    ...options,
    data: {
      ...readData,
      getRoomUpdatesByRoomId: {
        ...readData.getRoomUpdatesByRoomId,
        rows: concat(roomUpdate, readData.getRoomUpdatesByRoomId?.rows),
      },
    },
  })
}

function updateDeleted({ client, roomUpdate }) {
  if (!roomUpdate) return

  client.evict({ id: FragmentOptions.roomUpdate(roomUpdate.id).id })
}

function updateUpdated({ client, roomUpdate }) {
  if (!roomUpdate?.id) return

  const fragment = FragmentOptions.roomUpdate(roomUpdate.id)
  const data = client.readFragment(fragment)
  if (!data) return

  client.writeFragment({
    ...fragment,
    data: merge({}, data, roomUpdate),
  })
}

function emojiDeleted({ client, roomUpdate, emojiToRemove }) {
  if (!roomUpdate?.id) return

  const fragment = FragmentOptions.roomUpdate(roomUpdate.id)
  const data = client.readFragment(fragment)
  if (!data) return

  const filteredEmojis = data.emojis.filter(
    emoji => emoji.id !== emojiToRemove.id,
  )

  client.writeFragment({
    ...fragment,
    data: { ...data, emojis: filteredEmojis },
  })
}

export { emojiDeleted, updateCreated, updateDeleted, updateUpdated }
