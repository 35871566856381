import React, { useMemo } from 'react'
import ReactSelect from 'react-select'
import ReactSelectAsync from 'react-select/async'
import ReactSelectAsyncCreatable from 'react-select/async-creatable'
import ReactSelectCreatable from 'react-select/creatable'
import { AsyncPaginate } from 'react-select-async-paginate'
import PropTypes from 'prop-types'

import styled, { useTheme } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'

import * as Components from './Components'

function getSelectType({ async, creatable, isPaginated }) {
  if (async && creatable) {
    return ReactSelectAsyncCreatable
  }
  if (async) {
    return ReactSelectAsync
  }
  if (creatable) {
    return ReactSelectCreatable
  }
  if (isPaginated) {
    return AsyncPaginate
  }

  return ReactSelect
}

const StyledSelect = styled.div.attrs(({ async, creatable, isPaginated }) => ({
  as: getSelectType({ async, creatable, isPaginated }),
}))`
  height: ${mapToTheme('spacing.controlHeight')}px;
  font-size: 14px;

  ${margin}
  ${layout.width}
`

StyledSelect.defaultProps = {
  className: 'react-select-container',
  classNamePrefix: 'react-select',
}

StyledSelect.propTypes = {
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

const THEME = theme => {
  const { negative, primary } = theme?.colors || {}

  return {
    borderRadius: 10,
    colors: {
      danger: negative,
      dangerLight: `${negative}80`,
      neutral0: theme?.colors?.bg?.primary,
      neutral5: 'hsl(0, 0%, 95%)',
      neutral10: 'hsl(0, 0%, 90%)',
      neutral20: 'hsl(0, 0%, 80%)',
      neutral30: 'hsl(0, 0%, 70%)',
      neutral40: 'hsl(0, 0%, 60%)',
      neutral50: 'hsl(0, 0%, 50%)',
      neutral60: 'hsl(0, 0%, 40%)',
      neutral70: 'hsl(0, 0%, 30%)',
      neutral80: 'hsl(0, 0%, 20%)',
      neutral90: 'hsl(0, 0%, 10%)',
      primary,
      primary25: `${primary}40`,
      primary50: `${primary}80`,
      primary75: `${primary}C0`,
    },
    spacing: {
      baseUnit: 2,
      controlHeight: theme?.inputs?.height,
      menuGutter: 4,
    },
  }
}

function Select({
  async,
  components,
  creatable,
  helper,
  isPaginated,
  withPortal,
  ...rest
}) {
  const theme = useTheme()

  const selectTheme = useMemo(() => THEME(theme), [theme])

  return (
    <StyledSelect
      {...rest}
      async={async}
      components={{ ...Components, ...components }}
      creatable={creatable}
      isPaginated={isPaginated}
      menuPortalTarget={withPortal ? document.body : undefined}
      styles={
        withPortal
          ? {
              menuPortal: base => ({
                ...base,
                zIndex: 9999,
              }),
            }
          : {}
      }
      theme={selectTheme}
    />
  )
}

Select.defaultProps = {
  async: false,
  components: null,
  creatable: false,
  helper: null,
  isPaginated: false,
  small: false,
  width: 1,
  withPortal: false,
}

Select.propTypes = {
  async: PropTypes.bool,
  components: PropTypes.object,
  creatable: PropTypes.bool,
  helper: PropTypes.node,
  isPaginated: PropTypes.bool,
  small: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withPortal: PropTypes.bool,
}

export default Select
