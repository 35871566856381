import { useCallback, useState } from 'react'

import noop from 'lodash/noop'

export default function useEntityModal({ onClose = noop, onOpen = noop } = {}) {
  const [modal, setModal] = useState({
    isOpen: false,
    entity: undefined,
  })

  const handleClose = useCallback(async () => {
    const overrides = await onClose({ modal })

    setModal({
      isOpen: false,
      entity: undefined,
      ...overrides,
    })
  }, [onClose, modal])

  const handleOpen = useCallback(
    async entityOrEvent => {
      const overrides = await onOpen({ modal, entityOrEvent })

      setModal({
        isOpen: true,
        entity: entityOrEvent?.target ? undefined : entityOrEvent,
        ...overrides,
      })
    },
    [onOpen, modal],
  )

  return [
    modal,
    {
      closeModal: handleClose,
      openModal: handleOpen,
    },
  ]
}
