import React, { useEffect } from 'react'
import { useHistory } from 'react-router'

import {
  BackgroundImage,
  Container,
  Content,
  Info,
  Name,
} from 'Components/Layout/Auth/styles'
import { Flex, Link, Loader, Logo, Row, Text } from 'Components/UI'

import { APP_NAME } from 'Config'

import { useAppContext } from 'Hooks'

import { ROOT } from 'Router/routes'

import _ from 'Services/I18n'

import Utils from 'Utils'

function Redirector() {
  const { me } = useAppContext()
  const history = useHistory()

  useEffect(() => {
    const isAuthorized = !!me
    const route = Utils.Me.getRootRoute(me)

    history.replace(isAuthorized ? route : ROOT)
  }, [history, me])

  return (
    <Container>
      <BackgroundImage />
      <Info>
        <Link clean to={ROOT}>
          <Row center>
            <Logo />
            <Name ml={12}>{APP_NAME}</Name>
          </Row>
        </Link>
        <Row mt={3}>
          <Text>{_('general.slogan')}</Text>
        </Row>
      </Info>
      <Content>
        <Flex
          alignItems="center"
          flex="1 0"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <Loader />
          Redirect...
        </Flex>
      </Content>
    </Container>
  )
}

export default Redirector
