import { USER_ROLE } from './ids'

export const PASSWORD_REGEX =
  '^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*_-]).{8,}$'

export const ROLE_NAMES = {
  [USER_ROLE.USER]: 'User',
  [USER_ROLE.ADMIN]: 'Admin',
  [USER_ROLE.SUPER_ADMIN]: 'Super Admin',
}

export const LONG_DASH = '—'

export const PHONE_REGEX = /^[\d+\-()]+$/
