import React from 'react'

import { FiPlus, FiSearch } from 'react-icons/fi'

import {
  Button,
  Column,
  DateInput,
  DateRangeInput,
  DateRangePicker,
  Row,
  Text,
  Toast,
} from 'Components/UI'
import { Element } from 'Components/UI/Flex'
import { Checkbox, Input, Select } from 'Components/UI/Forms'

import toast from 'Services/Toast'

import Charts from './Charts'
import { Block, Container, Wrapper } from './styles'

function UIKit() {
  return (
    <Container pt={4}>
      <Text big bolder ml={6}>
        Charts
      </Text>
      <Wrapper>
        <Block>
          <Charts.Pie />
        </Block>
        <Block>
          <Charts.Area />
        </Block>
        <Block>
          <Charts.RadialBar />
        </Block>
        <Block>
          <Charts.Funnel />
        </Block>
        <Block>
          <Charts.Radar />
        </Block>
      </Wrapper>

      <Text big bolder ml={6}>
        UI Kit
      </Text>
      <Wrapper>
        <Block>
          <Text bolder mb={4}>
            Toasts
          </Text>

          <Column>
            <Button
              mb={3}
              outline
              onClick={() =>
                toast.info({ text: 'Info toast with title', title: 'Info' })
              }
            >
              Show default toast
            </Button>

            <Button
              mb={3}
              negative
              outline
              onClick={() =>
                toast.error({ title: 'Error', text: 'Error toast' })
              }
            >
              Show error toast
            </Button>

            <Button
              mb={3}
              outline
              positive
              onClick={() =>
                toast.success({ title: 'Success', text: 'Success toast' })
              }
            >
              Show success toast
            </Button>
          </Column>
        </Block>
        <Block>
          <Text bolder mb={4}>
            Selects
          </Text>

          <Column fullWidth px={4}>
            <Select
              error="Error occurred"
              label="Select something"
              mb={3}
              options={[
                { value: '1', label: 'Test 1' },
                { value: '2', label: 'Test 2' },
              ]}
            />

            <Select
              isMulti
              label="Select something"
              mb={3}
              options={[
                { value: '1', label: 'Test 1' },
                { value: '2', label: 'Test 2' },
              ]}
            />
          </Column>
        </Block>
        <Block>
          <Text bolder mb={4}>
            Checkboxes
          </Text>

          <Column fullWidth px={4}>
            <Checkbox checked label="Checked" mb={3} />

            <Checkbox checked={false} label="Unchecked" mb={3} />

            <Checkbox
              disabled
              error="Something goes wrong"
              label="Disabled"
              mb={3}
            />

            <Checkbox error="Should check" label="Disabled" mb={3} />
          </Column>
        </Block>

        <Block>
          <Text bolder mb={4}>
            Icon Buttons
          </Text>

          <Column center px={4}>
            <Button
              icon
              mb={3}
              secondary
              squared
              onClick={() => toast.error(Toast)}
            >
              <FiPlus />
            </Button>

            <Button icon mb={3} squared>
              <FiPlus />
            </Button>

            <Button big icon mb={3} squared>
              <FiPlus />
            </Button>
          </Column>
        </Block>

        <Block>
          <Text bolder mb={4}>
            Disabled Icon Buttons
          </Text>

          <Column center px={4}>
            <Button disabled icon mb={3} secondary squared>
              <FiPlus />
            </Button>

            <Button disabled icon mb={3} squared>
              <FiPlus />
            </Button>

            <Button big disabled icon mb={3} squared>
              <FiPlus />
            </Button>
          </Column>
        </Block>

        <Block>
          <Text bolder mb={4}>
            Normal Buttons
          </Text>

          <Column px={4} width={180}>
            <Button mb={3} secondary>
              Normal
            </Button>

            <Button mb={3}>Normal</Button>

            <Button mb={3} outline>
              Normal
            </Button>

            <Button big mb={3}>
              Normal
            </Button>
          </Column>
        </Block>

        <Block>
          <Text bolder mb={4}>
            Disabled Normal Buttons
          </Text>

          <Column px={4} width={180}>
            <Button disabled mb={3} secondary>
              Normal
            </Button>

            <Button disabled mb={3}>
              Normal
            </Button>

            <Button disabled mb={3} outline>
              Normal
            </Button>

            <Button big disabled mb={3}>
              Normal
            </Button>
          </Column>
        </Block>

        <Block>
          <Text bolder mb={4}>
            Inputs
          </Text>

          <Column fullWidth px={4}>
            <Input icon={<FiSearch />} mb={3} placeholder="Search..." />

            <Input label="Phone number" mb={3} />

            <Input label="Phone number" mb={3} value="+1 (243) 543 2395" />

            <Input label="Phone number" mb={3} type="textarea" />
          </Column>
        </Block>

        <Block>
          <Text bolder mb={4}>
            Input States
          </Text>

          <Column fullWidth px={4}>
            <Input
              error="Sorry, the entered code is incorrect"
              label="Phone number"
              mb={3}
              value="+1 (243) 543 2395"
            />

            <Input
              disabled
              label="Phone number"
              mb={3}
              value="+1 (243) 543 2395"
            />

            <Input disabled label="Phone number" mb={3} />
          </Column>
        </Block>
      </Wrapper>

      <Wrapper>
        <Column>
          <Column mb={3}>
            <Row mb={3}>
              <Text big bold>
                Date Range Input
              </Text>
            </Row>

            <Row mb={3}>
              <DateRangeInput width={1} />
            </Row>

            <Row mb={3}>
              <DateRangeInput disabled width={1} />
            </Row>

            <Row>
              <DateRangeInput
                value={{
                  from: new Date(2021, 0, 1),
                  to: new Date(2021, 1, 1),
                }}
                width={1}
              />
            </Row>
          </Column>

          <Column mb={3}>
            <Row mb={3}>
              <Text big bold>
                Date Range Picker
              </Text>
            </Row>

            <Row>
              <Element mr={2}>
                <DateRangePicker />
              </Element>

              <Element>
                <DateRangePicker
                  value={{
                    from: new Date(2021, 0, 1),
                    to: new Date(2021, 1, 1),
                  }}
                />
              </Element>
            </Row>
          </Column>

          <Column mb={3}>
            <Row mb={3}>
              <Text big bold>
                Date Input
              </Text>
            </Row>

            <Row mb={3}>
              <DateInput width={1} />
            </Row>

            <Row mb={3}>
              <DateInput disabled width={1} />
            </Row>

            <Row mb={3}>
              <DateInput value={new Date(2021, 0, 1)} width={1} />
            </Row>
          </Column>
        </Column>
      </Wrapper>
    </Container>
  )
}

export default UIKit
