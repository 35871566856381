import styled from 'styled-components'
import { space } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { Column } from 'Components/UI'

export const Container = styled.div`
  ${space}
`

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 10px 10px;
`

export const Block = styled(Column)`
  justify-content: center;
  align-items: center;
  min-width: 300px;
  flex: 1 0;
  width: 50%;
  height: 300px;
  margin: 10px 10px 0 0;
  border: 1px solid ${themeGet('colors.border.default')};
`
