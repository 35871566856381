import { useCallback, useRef } from 'react'

import get from 'lodash/get'

import { Modal } from 'Components/UI'

export default function useModal({ onClose, ...rest }) {
  const close = useRef(null)

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const handleClose = useCallback(
    (success = false) => {
      close.current()
      onClose(success)
    },
    [onClose],
  )

  return [
    {
      ...Modal.pickProps(rest),
      onClose: handleClose,
      onMount: handleMount,
    },
  ]
}
