import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import { PARTICIPANT_ROLE } from 'Constants/ids'
import { FOLDER_TYPE, ROOM_KIND, ROOM_PRIVACY } from 'Constants/room'

import getRoomByIdQuery from 'GraphQL/Queries/Room/getRoomById.graphql'

function useRoom({ roomId, fetchPolicy }) {
  const roomQuery = useQuery(getRoomByIdQuery, {
    variables: { id: roomId },
    skip: !roomId,
    fetchPolicy,
  })

  return useMemo(
    () => ({
      loading: roomQuery.loading,
      refetch: roomQuery.refetch,
      room: {
        ...roomQuery.data?.getRoomById,
        isRoomAdministrator:
          roomQuery.data?.getRoomById?.participation?.role ===
          PARTICIPANT_ROLE.ADMIN,
        isArchived:
          roomQuery.data?.getRoomById?.participation?.folder ===
          FOLDER_TYPE.ARCHIVE,
        isPinRoom: roomQuery.data?.getRoomById?.participation?.isPinned,
        isPurpleRoom: roomQuery.data?.getRoomById?.kind === ROOM_KIND.PURPLE,
        isPrivateRoom:
          roomQuery.data?.getRoomById?.privacy === ROOM_PRIVACY.PRIVATE,
      },
    }),
    [roomQuery],
  )
}

export default useRoom
