import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Column } from 'Components/UI'

export const UserName = styled(Column)`
  margin-bottom: 2px;
  text-align: right;
  line-height: 1.2em;
  color: ${themeGet('colors.white')};
`
