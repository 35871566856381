import React from 'react'
import PropTypes from 'prop-types'

import { omit, pick } from '@styled-system/props'

import { Select as SelectBase } from 'Components/UI'

import { ErrorWrapper, Wrapper } from '../styles'

function Select({ additional, displayError, error, ...rest }) {
  return (
    <Wrapper {...pick(rest)}>
      <SelectBase additional={additional} error={!!error} {...omit(rest)} />

      {displayError && error && <ErrorWrapper>{error}</ErrorWrapper>}
    </Wrapper>
  )
}

Select.defaultProps = {
  additional: {},
  displayError: true,
  error: null,
}

Select.propTypes = {
  additional: PropTypes.object,
  displayError: PropTypes.bool,
  error: PropTypes.string,
}
export default Select
