import React from 'react'
import PropTypes from 'prop-types'

import { Row } from 'Components/UI/Flex'
import Text from 'Components/UI/Text'

import { Container } from './styles'

function InputLabels({ children, width, required, title, ...rest }) {
  return (
    <Container {...rest} width={width}>
      <Row mb={2}>
        <Text bold middle>
          {title}
        </Text>
        {required && (
          <Text bold middle ml={1} primary>
            *
          </Text>
        )}
      </Row>
      {children}
    </Container>
  )
}

InputLabels.defaultProps = {
  children: null,
  required: false,
  width: 1,
}

InputLabels.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  required: PropTypes.bool,
  title: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default InputLabels
