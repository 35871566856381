import { split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'

import noop from 'lodash/noop'

import { API } from 'Config'

import { setChatWebSocketLink, setWebSocketLink } from 'Services/Shared'

import ChatWebSocketLink from './chatSubscriptionLink'
import createHttpLink from './httpLink'
import MainWebSocketLink from './subscriptionLink'

function splitBySubscription({ query }) {
  const { kind, operation } = getMainDefinition(query)

  return kind === 'OperationDefinition' && operation === 'subscription'
}

function isChatOperation({ getContext, operationName }) {
  return getContext().chat === true || operationName?.startsWith('Chat')
}

function isAdminOperation({ getContext }) {
  return getContext().admin === true
}

function createMainSubscriptionLink() {
  const link = new MainWebSocketLink(`${API.WS}/graphql`)
  setWebSocketLink(link)

  return link
}

function createChatSubscriptionLink() {
  const link = new ChatWebSocketLink(`${API.CHAT_WS}/socket`)
  setChatWebSocketLink(link)
  return link
}

export default function createRequestList() {
  return split(
    isChatOperation,
    split(
      splitBySubscription,
      createChatSubscriptionLink(),
      createHttpLink(`${API.CHAT}/graphql`),
    ),
    split(
      isAdminOperation,
      split(
        splitBySubscription,
        noop,
        createHttpLink(`${API.URL}/admin/graphql`),
      ),
      split(
        splitBySubscription,
        createMainSubscriptionLink(),
        createHttpLink(`${API.URL}/graphql`),
      ),
    ),
  )
}
