import { createTheme } from '@mui/material/styles'

import palette from './palette'

const TINY_SIZE = '0.5rem'
const SMALL_SIZE = '1rem'
const MEDIUM_SIZE = '2rem'
const LARGE_SIZE = '3rem'

export default createTheme({
  palette: {
    primary: {
      main: palette.primary,
    },
    secondary: {
      main: palette.primaryDark,
    },
  },

  components: {
    MuiSvgIcon: {
      defaultProps: {
        fontSize: 'inherit',
      },
      // somehow 'styleOverrides' is not working with 'fontSizeMedium' and other fontSizes
      variants: [
        {
          props: { fontSize: 'tiny' },
          style: {
            fontSize: TINY_SIZE,
          },
        },
        {
          props: { fontSize: 'small' },
          style: {
            fontSize: SMALL_SIZE,
          },
        },
        {
          props: { color: 'white' },
          style: {
            color: palette.white,
          },
        },
        {
          props: { fontSize: 'medium' },
          style: {
            fontSize: MEDIUM_SIZE,
          },
        },
        {
          props: { fontSize: 'large' },
          style: {
            fontSize: LARGE_SIZE,
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        sizeMedium: {
          fontSize: MEDIUM_SIZE,
        },
        sizeLarge: {
          fontSize: LARGE_SIZE,
        },
      },
    },
  },
})
