import map from 'lodash/map'
import reject from 'lodash/reject'

import { MAX_LIMIT } from 'Constants/query'

import moreMessagesQuery from 'GraphQL/Queries/Chat/moreMessages.graphql'

const emojiAdded = ({ client, emoji: addEmoji }) => {
  if (!addEmoji) return

  const { message } = addEmoji

  if (!message) return

  const query = {
    query: moreMessagesQuery,
    variables: {
      channelId: message.channel?.id,
      first: MAX_LIMIT,
    },
  }

  const data = client.readQuery(query)

  if (!data) {
    return
  }

  const newEmojiRef = {
    id: addEmoji.id,
    user: addEmoji.user,
    name: addEmoji.name,
    __typename: 'ChatEmoji',
  }

  let messages
  if (message.thread) {
    messages = map(data.moreMessages.messages, messageItem => {
      if (messageItem.id !== message.thread.id) {
        return messageItem
      }

      return {
        ...messageItem,
        emojis: [...messageItem.emojis, newEmojiRef],
      }
    })
  } else {
    messages = map(data.moreMessages.messages, messageItem => {
      if (messageItem.id !== message.id) {
        return messageItem
      }

      return {
        ...messageItem,
        emojis: [...messageItem.emojis, newEmojiRef],
      }
    })
  }

  client.writeQuery({
    ...query,
    data: {
      ...data,
      moreMessages: { ...data.moreMessages, messages },
    },
  })
}

const emojiRemoved = ({ client, emoji: removeEmoji }) => {
  if (!removeEmoji) return

  const { message, id: emojiId } = removeEmoji

  if (!message || !emojiId) return

  const query = {
    query: moreMessagesQuery,
    variables: {
      channelId: message.channel.id,
      first: MAX_LIMIT,
    },
  }

  const data = client.readQuery(query)

  if (!data) {
    return
  }

  let messages
  if (message.thread) {
    messages = map(data.moreMessages.messages, messageItem => {
      if (messageItem.id !== message.thread.id) {
        return messageItem
      }

      return {
        ...messageItem,
        emojis: reject(messageItem.emojis, { id: emojiId }),
      }
    })
  } else {
    messages = map(data.moreMessages.messages, messageItem => {
      if (messageItem.id !== message.id) {
        return messageItem
      }

      return {
        ...messageItem,
        emojis: reject(messageItem.emojis, { id: emojiId }),
      }
    })
  }

  client.writeQuery({
    ...query,
    data: {
      ...data,
      moreMessages: { ...data.moreMessages, messages },
    },
  })
}

export { emojiAdded, emojiRemoved }
