const APP_CONFIGURATION_APPLICATION_ID = {
  DC: 'dc',
  KAPUA: 'kapua',
  SOS: 'sos',
  UW: 'uw',
}

const APP_CONFIGURATION_PLATFORM_ID = {
  ANDROID: 'android',
  IOS: 'ios',
  OTHER: 'other',
}

export const APP_CONFIGURATION_APP_TYPE_ID = {
  MAJOR: 'major',
  MINOR: 'minor',
}

export const APP_CONFIGURATION_APP_KIND = {
  [APP_CONFIGURATION_APPLICATION_ID.DC]: {
    id: APP_CONFIGURATION_APPLICATION_ID.DC,
    label: 'DC',
  },
  [APP_CONFIGURATION_APPLICATION_ID.KAPUA]: {
    id: APP_CONFIGURATION_APPLICATION_ID.KAPUA,
    label: 'Kapua',
  },
  [APP_CONFIGURATION_APPLICATION_ID.SOS]: {
    id: APP_CONFIGURATION_APPLICATION_ID.SOS,
    label: 'SOS',
  },
  [APP_CONFIGURATION_APPLICATION_ID.UW]: {
    id: APP_CONFIGURATION_APPLICATION_ID.UW,
    label: 'UW',
  },
}

export const APP_CONFIGURATION_PLATFORM_TYPE = {
  [APP_CONFIGURATION_PLATFORM_ID.ANDROID]: {
    id: APP_CONFIGURATION_PLATFORM_ID.ANDROID,
    label: 'Android',
  },
  [APP_CONFIGURATION_PLATFORM_ID.IOS]: {
    id: APP_CONFIGURATION_PLATFORM_ID.IOS,
    label: 'iOS',
  },
  [APP_CONFIGURATION_PLATFORM_ID.OTHER]: {
    id: APP_CONFIGURATION_PLATFORM_ID.OTHER,
    label: 'Other',
  },
}

export const APP_CONFIGURATION_TYPE = {
  [APP_CONFIGURATION_APP_TYPE_ID.MAJOR]: {
    id: APP_CONFIGURATION_APP_TYPE_ID.MAJOR,
    label: 'Minimum',
  },
  [APP_CONFIGURATION_APP_TYPE_ID.MINOR]: {
    id: APP_CONFIGURATION_APP_TYPE_ID.MINOR,
    label: 'Minor',
  },
}

export const VERSION_REGEX = /^\d+\.\d+\.\d+$/

export const APP_CONFIGURATION_NOTIFICATION_FREQUENCY = {
  DAILY: { value: 'daily', label: 'Daily' },
  WEEKLY: { value: 'weekly', label: 'Weekly' },
  ON_LAUNCH: { value: 'onLaunch', label: 'On Launch' },
}
