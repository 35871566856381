import { AdminLayout } from 'Components/Layout'

import Admin from 'Pages/Admin'

import * as ROUTES from './routes'

export const ADMIN_ROUTES = {
  component: AdminLayout,
  path: ROUTES.ADMIN_ROOT,
  routes: [
    {
      component: Admin.Root,
      path: ROUTES.ADMIN_ROOT,
      exact: true,
    },

    {
      component: Admin.Admins,
      path: ROUTES.ADMIN_ADMINS(),
      exact: true,
    },
    {
      component: Admin.Users,
      path: ROUTES.ADMIN_USERS(),
      exact: true,
    },
    {
      component: Admin.UserProfile,
      path: ROUTES.ADMIN_USER_PROFILE(),
      exact: true,
    },

    {
      component: Admin.RoomSpecs,
      path: ROUTES.ADMIN_ROOM_SPECS(),
      exact: true,
    },
    {
      component: Admin.RoomSpecProfile,
      path: ROUTES.ADMIN_ROOM_SPECS_PROFILE(),
      exact: true,
    },

    {
      component: Admin.Organizations,
      path: ROUTES.ADMIN_ORGANIZATIONS(),
      exact: true,
    },
    {
      component: Admin.OrganizationProfile,
      path: ROUTES.ADMIN_ORGANIZATIONS_PROFILE(),
      exact: true,
    },
    {
      component: Admin.Rooms,
      path: ROUTES.ADMIN_ROOMS(),
      exact: true,
    },
    {
      component: Admin.Dashboards,
      path: ROUTES.ADMIN_DASHBOARD(),
      exact: true,
    },
    {
      component: Admin.DashboardProfile,
      path: ROUTES.ADMIN_DASHBOARD_PROFILE(),
      exact: true,
    },
    {
      component: Admin.Directories,
      path: ROUTES.ADMIN_DIRECTORIES,
      exact: true,
    },
    {
      component: Admin.DirectoryProfile,
      path: ROUTES.ADMIN_DIRECTORY_PROFILE(),
      exact: true,
    },
    {
      component: Admin.RoomTemplates,
      path: ROUTES.ADMIN_TEMPLATES(),
      exact: true,
    },
    {
      component: Admin.RoomTemplateProfile,
      path: ROUTES.ADMIN_TEMPLATE_PROFILE(),
      exact: true,
    },
    {
      component: Admin.ContacProfile,
      path: ROUTES.ADMIN_CONTACT_PROFILE(),
      exact: true,
    },
    {
      component: Admin.UpdateApp,
      path: ROUTES.UPDATE_APP,
      exact: true,
    },
    {
      component: Admin.CreateUpdateAppConfigurations,
      path: ROUTES.UPDATE_APP_CONFIGURATION,
      exact: true,
    },
    // TODO: implement later; needs to fix upper (admin) layout
    // {
    //   component: NotFound,
    //   path: '*',
    // },
  ],
}
