import { LANGUAGE } from './ids'

export const AUTH_STATE = 'auth'
export const AUTH_TOKEN_DEFAULTS = {
  accessToken: null,
  refreshToken: null,
}
export const AUTH_ACTIONS = {
  SET: `${AUTH_STATE}/set`,
  CLEAR: `${AUTH_STATE}/clear`,
}

export const I18N_STATE = 'i18n'
export const I18N_DEFAULTS = {
  locale: LANGUAGE.EN,
}
export const I18N_ACTIONS = {
  SET: `${I18N_STATE}/set`,
}

export const APP_KIND_STATE = 'appKind'
export const APP_KIND_ACTIONS = {
  SET: `${APP_KIND_STATE}/set`,
  CLEAR: `${APP_KIND_STATE}/clear`,
}
