import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import noop from 'lodash/noop'

import { Container, PickerInput } from './styles'
import { isSelectingFirstDay } from './utils'

import { Picker } from '../DatePicker/styles'
import Popover from '../Popover'

function DateRangeInput({ value, disabled, onChange, onBlur, ...rest }) {
  const { from: initialFrom, to: initialTo } = value

  const [visible, setVisible] = useState(false)

  const [from, setFrom] = useState(initialFrom ?? null)
  const [to, setTo] = useState(initialTo ?? null)
  const [enteredTo, setEnteredTo] = useState(initialTo ?? null)

  useEffect(() => {
    if (initialFrom !== from) {
      setTo(initialFrom)
    }
  }, [from, initialFrom, value])

  useEffect(() => {
    if (initialTo !== to) {
      setTo(initialTo)
      setEnteredTo(initialTo)
    }
  }, [initialTo, to])

  const handleClickOutside = useCallback(() => {
    setVisible(false)
  }, [])

  const handleShowCalendar = useCallback(() => {
    setVisible(true)
  }, [])

  const handleFromChange = useCallback(day => {
    setFrom(day)
    setTo(null)
    setEnteredTo(null)
  }, [])

  const handleFromClear = useCallback(() => {
    setFrom(null)
    setEnteredTo(null)

    if (!to) {
      onChange({})
    }
  }, [onChange, to])

  const handleToClear = useCallback(() => {
    setTo(null)
    setEnteredTo(null)

    if (!from) {
      onChange({})
    }
  }, [from, onChange])

  const handleToChange = useCallback(
    day => {
      if (from && day < from) {
        return
      }

      setFrom(from)
      setTo(day)
      setEnteredTo(day)
    },
    [from],
  )

  const handleRangeChange = useCallback(
    (day, modifiers = {}) => {
      if (modifiers.disabled || !day) {
        return
      }

      if (from && to && day >= from && day <= to) {
        setFrom(null)
        setTo(null)
        setEnteredTo(null)
      } else if (isSelectingFirstDay(from, to, day)) {
        setFrom(day)
        setTo(null)
        setEnteredTo(null)
      } else {
        setFrom(from)
        setTo(day)
        setEnteredTo(day)

        onChange({ from, to: day })
      }
    },
    [from, onChange, to],
  )

  const handleDayMouseEnter = useCallback(
    day => {
      if (isSelectingFirstDay(from, to, day)) {
        return
      }

      setEnteredTo(day)
    },
    [from, to],
  )

  const handleBlur = useCallback(
    (...args) => {
      if (from && !to) {
        setFrom(null)
        setTo(null)
        setEnteredTo(null)
      }

      onBlur(...args)
    },
    [from, onBlur, to],
  )

  return (
    <Popover
      blockProps={{ p: 0 }}
      content={
        <Picker
          border="none"
          modifiers={{ start: from, end: enteredTo }}
          selectedDays={[from, { from, to: enteredTo }]}
          onDayClick={handleRangeChange}
          onDayMouseEnter={handleDayMouseEnter}
        />
      }
      interactive
      visible={visible}
      onClickOutside={handleClickOutside}
      onHide={handleBlur}
    >
      <Container disabled={disabled} {...pick(rest)}>
        <PickerInput
          inputProps={{ disabled, onClear: handleFromClear }}
          value={from}
          onDayChange={handleFromChange}
          onDayPickerShow={handleShowCalendar}
        />
        &nbsp;—&nbsp;
        <PickerInput
          inputProps={{ disabled, onClear: handleToClear }}
          value={to}
          onDayChange={handleToChange}
          onDayPickerShow={handleShowCalendar}
        />
      </Container>
    </Popover>
  )
}

DateRangeInput.defaultProps = {
  disabled: false,
  value: {},
  onChange: noop,
  onBlur: noop,
}

DateRangeInput.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}

export default DateRangeInput
