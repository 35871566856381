import React, { useEffect } from 'react'
import { useHistory } from 'react-router'

import { Row } from 'Components/UI'

import { signOut } from 'Services/SignOut'
import { useAppKind } from 'Services/Store/appKind'

function SignOut() {
  const history = useHistory()
  const { appKind } = useAppKind()

  useEffect(() => {
    async function asyncSignOut() {
      await signOut(history, appKind)
    }

    asyncSignOut()
  }, [history, appKind])

  return <Row />
}

export default SignOut
