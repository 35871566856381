import DayPicker from 'react-day-picker'

import styled from 'styled-components'
import border from '@styled-system/border'
import { themeGet } from '@styled-system/theme-get'

import * as Styles from 'Components/Styles'

export const Picker = styled(DayPicker)`
  background-color: ${themeGet('colors.bg.primary')};
  box-shadow: ${themeGet('shadow')};

  ${Styles.border()}

  .DayPicker-Day {
    border-radius: 0 !important;

    :hover & {
      background-color: ${themeGet('colors.primary')}17;
    }
  }

  .DayPicker-Day--start,
  .DayPicker-Day--end {
    background-color: ${themeGet('colors.primary')} !important;
    color: ${themeGet('colors.bg.primary')} !important;
  }

  .DayPicker-Day--selected {
    border: none !important;
    outline: none !important;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: ${themeGet('colors.primary')}17 !important;
    color: ${themeGet('colors.primary')};
  }

  ${border}
`

Picker.defaultProps = {
  firstDayOfWeek: 1,
  fixedWeeks: true,
}
