import styled from 'styled-components'
import { borderWidth, margin } from 'styled-system'
import { pick } from '@styled-system/props'

import MaterialDivider from '@mui/material/Divider'

const Divider = styled(MaterialDivider).attrs(props => ({
  sx: pick(props),
}))`
  ${margin}
  ${borderWidth}
`

export default Divider
