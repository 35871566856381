import { AuthLayout, RootLayout } from 'Components/Layout'

import { MOBILE_APP_KIND } from 'Constants/app'
import { USER_ROLE } from 'Constants/ids'

import SignOut from 'Pages/Auth/SignOut'
import NotFound from 'Pages/NotFound'
import Public from 'Pages/Public'
import UIKit from 'Pages/UIKit'

import { ADMIN_ROUTES } from './admin'
import { APP_ROUTES } from './app'
import { AUTH_ROUTES } from './auth'
import { PORTAL_ROUTES } from './portal'
import * as ROUTES from './routes'

const INVITATION_KINDS = [
  MOBILE_APP_KIND.DC,
  MOBILE_APP_KIND.SOS,
  MOBILE_APP_KIND.KAPUA,
  MOBILE_APP_KIND.UW,
]

export default function router(user) {
  const routes = [
    {
      component: Public.Root,
      path: ROUTES.ROOT,
      exact: true,
    },
    {
      component: Public.Redirector,
      path: ROUTES.REDIRECTOR,
      exact: true,
    },
    {
      component: Public.MobileRoomRedirector,
      path: ROUTES.MOBILE_ROOM_INVITATION(),
      exact: true,
    },
    ...INVITATION_KINDS.map(kind => ({
      component: Public.MobileRedirector,
      path: ROUTES.MOBILE_INVITATION(kind),
      exact: true,
      props: { kind },
    })),
    {
      component: AuthLayout,
      path: ROUTES.SOCIAL_CALLBACK(),
      routes: [
        {
          component: Public.SocialCallback,
          path: ROUTES.SOCIAL_CALLBACK(),
          exact: true,
        },
      ],
    },
    PORTAL_ROUTES,
  ]

  if (user) {
    switch (user.role) {
      case USER_ROLE.ADMIN:
      case USER_ROLE.SUPER_ADMIN:
        routes.push(ADMIN_ROUTES)
        break
      case USER_ROLE.USER:
        routes.push(APP_ROUTES)
        break

      default:
        break
    }
  } else {
    routes.push(AUTH_ROUTES)
  }

  routes.push({
    component: SignOut,
    path: ROUTES.SIGN_OUT,
    exact: true,
  })

  routes.push({
    component: UIKit,
    path: ROUTES.UI_KIT,
    exact: true,
  })

  routes.push({
    component: NotFound,
  })

  return [
    {
      component: RootLayout,
      routes,
    },
  ]
}
