const palette = {
  black: '#000000',
  white: '#ffffff',

  secondaryBlack: '#2b2b2b',

  primaryDark: '#ee7b6f',
  primary: '#ed978e',
  primaryLight: '#f6bfb9',
  primaryLightest: '#f9d3cf',

  grayDark: '#4a4b4c',
  gray: '#b6b6b6',
  grayLight: '#d3d3d3',
  grayLightest: '#f5f5f5',

  positive: '#6bc877',
  positiveDark: '#3ea74b',
  positiveLight: '#96d89e',
  negative: '#e95f5f',
  negativeDark: '#e74e4e',
  negativeLight: '#f09393',
  warning: '#fccc6f',
  warningDark: '#ffc047',

  dodgerBlue: '#449aff',

  smokeDark: '#a1a1a1',
  smoke: '#d9d9d9',
  smokeLight: '#e1e1e1',
  smokeLightest: '#dddddd',
}

export default palette
