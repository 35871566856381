import React from 'react'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'

import { Container } from './styles'

function Public({ route }) {
  return <Container>Public layout {renderRoutes(route.routes)}</Container>
}

Public.propTypes = {
  route: PropTypes.element.isRequired,
}

export default Public
