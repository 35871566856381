import React, { useCallback } from 'react'

import { FiEdit3, FiTrash } from 'react-icons/fi'

import { Button, Tip } from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

import useAppContext from './useAppContext'

export default function useMutationTableActions({
  row,
  i18nKey,
  editRow,
  deleteRow,
}) {
  const { isAdmin, isSuperAdmin } = useAppContext()

  const entity = row?.original

  const s = useScopedI18n(i18nKey)

  const handleEdit = useCallback(() => {
    editRow(entity)
  }, [editRow, entity])

  const handleDelete = useCallback(() => {
    deleteRow(entity)
  }, [deleteRow, entity])

  const canInteract = isAdmin || isSuperAdmin

  const renderCommonActions = useCallback(
    () => (
      <>
        {canInteract && (
          <Tip content={s('edit')}>
            <Button icon small onClick={handleEdit}>
              <FiEdit3 />
            </Button>
          </Tip>
        )}

        {canInteract && (
          <Tip content={s('delete')}>
            <Button icon ml={1} small onClick={handleDelete}>
              <FiTrash />
            </Button>
          </Tip>
        )}
      </>
    ),
    [canInteract, handleDelete, handleEdit, s],
  )

  return [renderCommonActions]
}
