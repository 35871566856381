import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import { MAX_LIMIT } from 'Constants/query'

import getRoomParticipantsQuery from 'GraphQL/Queries/Room/roomParticipants.graphql'

function useRoomParticipants(roomId) {
  const roomParticipantsQuery = useQuery(getRoomParticipantsQuery, {
    variables: { roomId, limit: MAX_LIMIT },
  })

  const participants = useMemo(
    () => roomParticipantsQuery.data?.roomParticipants?.rows ?? [],
    [roomParticipantsQuery.data?.roomParticipants?.rows],
  )

  return [participants, roomParticipantsQuery]
}

export default useRoomParticipants
