import loadable from '@loadable/component'

const Portal = {
  Root: loadable(() => import('./Root' /* webpackChunkName: "portal-root" */)),
  Room: loadable(() => import('./Room' /* webpackChunkName: "portal-home" */)),
  ConfirmEmail: loadable(() =>
    import('./ConfirmEmail' /* webpackChunkName: "portal-confirmEmail" */),
  ),
}

export default Portal
