import { PortalLayout } from 'Components/Layout'

import NotFound from 'Pages/NotFound'
import Portal from 'Pages/Portal'

import * as ROUTES from './routes'

export const PORTAL_ROUTES = {
  component: PortalLayout,
  path: ROUTES.PORTAL_ROOT,
  routes: [
    {
      component: Portal.Root,
      path: ROUTES.PORTAL_ROOT,
      exact: true,
    },
    // reserve path to confirm email if user closed page
    {
      component: Portal.ConfirmEmail,
      path: ROUTES.PORTAL_CONFIRM_EMAIL,
      exact: true,
    },
    {
      component: Portal.Room,
      path: ROUTES.PORTAL_ROOM,
      exact: true,
    },
    {
      component: NotFound,
      path: '*',
    },
  ],
}
