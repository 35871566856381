import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { flex } from 'styled-system'
import { layout } from '@styled-system/layout'
import { space } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'
import { typography } from '@styled-system/typography'

import { flexCenter } from 'Components/Styles'

const outlineCss = ({ outline }) =>
  outline &&
  css`
    color: ${mapToTheme('buttons.outline.color')};
    border-color: ${mapToTheme('buttons.outline.border')};
    background-color: transparent !important;

    &:focus,
    &:hover {
      border-color: ${mapToTheme('buttons.outline.hover.border')};
      color: ${mapToTheme('buttons.outline.hover.color')};
    }

    &:active {
      border-color: ${mapToTheme('buttons.outline.active.border')};
      color: ${mapToTheme('buttons.outline.active.color')};
    }

    &:disabled {
      border-color: ${mapToTheme('buttons.outline.disabled.border')};
      color: ${mapToTheme('buttons.outline.disabled.color')};
    }
  `

const iconCss = ({ icon }) =>
  icon &&
  css`
    flex-shrink: 0;
    width: ${mapToTheme('buttons.height')}px;
    height: ${mapToTheme('buttons.height')}px;
    padding: 0;

    svg {
      width: ${mapToTheme('buttons.icon.size')}px;
      height: ${mapToTheme('buttons.icon.size')}px;
    }
  `

const textWithIconCss = ({ textWithIcon }) =>
  textWithIcon &&
  css`
    align-items: center;

    svg {
      width: ${mapToTheme('buttons.icon.size')}px;
      height: ${mapToTheme('buttons.icon.size')}px;
      margin: 0 ${themeGet('space.1')}px;
    }
  `

const smallCss = ({ small }) =>
  small &&
  css`
    line-height: 0;
  `

const disabledCss = ({ disabled }) =>
  disabled &&
  css`
    cursor: not-allowed;
    border-color: ${mapToTheme('buttons.disabled.border.color')};
    background-color: ${mapToTheme('buttons.disabled.backgroundColor')};
    color: ${mapToTheme('buttons.disabled.color')};

    &:active,
    &:focus,
    &:hover {
      border-color: ${mapToTheme('buttons.disabled.border.color')};
      background-color: ${mapToTheme('buttons.disabled.backgroundColor')};
      color: ${mapToTheme('buttons.disabled.color')};
      box-shadow: none;
    }
  `

const Button = styled.div`
  display: flex;
  position: relative;
  flex-shrink: 0;
  appearance: none;
  outline: none;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  text-decoration: none;
  ${flexCenter};

  border-style: solid;
  border-radius: ${mapToTheme('buttons.border.radius')}px;
  border-width: ${mapToTheme('buttons.border.width')}px;
  border-color: ${mapToTheme('buttons.border.color')};

  height: ${mapToTheme('buttons.height')}px;
  padding: ${mapToTheme('buttons.padding')};

  font-family: inherit;
  font-size: ${mapToTheme('buttons.fontSize')}px;
  font-weight: ${mapToTheme('buttons.fontWeight')};

  color: ${mapToTheme('buttons.color')};
  background-color: ${mapToTheme('buttons.backgroundColor')};

  transition: all ${themeGet('transitionTime.default')} ease-in-out;

  &:focus,
  &:hover {
    border-color: ${mapToTheme('buttons.hover.border.color')};
    background-color: ${mapToTheme('buttons.hover.backgroundColor')};
    color: ${mapToTheme('buttons.hover.color')};
    box-shadow: ${mapToTheme('buttons.hover.shadow')};
  }

  &:active {
    border-color: ${mapToTheme('buttons.active.border.color')};
    background-color: ${mapToTheme('buttons.active.backgroundColor')};
    color: ${mapToTheme('buttons.active.color')};
    box-shadow: ${mapToTheme('buttons.active.shadow')};
  }

  ${outlineCss}
  ${iconCss}
  ${textWithIconCss}
  ${smallCss}
  ${disabledCss}

  &:disabled {
    cursor: not-allowed;
    border-color: ${mapToTheme('buttons.disabled.border.color')};
    background-color: ${mapToTheme('buttons.disabled.backgroundColor')};
    color: ${mapToTheme('buttons.disabled.color')};
    pointer-events: none;
  }

  ${layout}
  ${space}
  ${typography}
  ${flex}
`

Button.propTypes = {
  big: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  icon: PropTypes.bool,
  outline: PropTypes.bool,
  secondary: PropTypes.bool,
  small: PropTypes.bool,
  squared: PropTypes.bool,
  success: PropTypes.bool,
  onClick: PropTypes.func,
}

Button.defaultProps = {
  big: false,
  disabled: false,
  error: false,
  icon: false,
  small: false,
  outline: false,
  secondary: false,
  squared: false,
  success: false,
  onClick: undefined,
}

export default Button
