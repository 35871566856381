import { useCallback } from 'react'

import { useStoreon } from 'storeon/react'

import { APP_KIND } from 'Constants/app'
import { APP_KIND_ACTIONS, APP_KIND_STATE } from 'Constants/store'

const DEFAULT_STATE = {
  appKind: APP_KIND.ADMIN,
}

const appKindModule = store => {
  store.on('@init', () => ({
    [APP_KIND_STATE]: DEFAULT_STATE,
  }))
  store.on(APP_KIND_ACTIONS.SET, (state, appKind) => ({
    [APP_KIND_STATE]: { ...state[APP_KIND_STATE], appKind },
  }))
  store.on(APP_KIND_ACTIONS.CLEAR, () => ({
    [APP_KIND_STATE]: DEFAULT_STATE,
  }))
}

function useAppKind() {
  const { [APP_KIND_STATE]: state, dispatch } = useStoreon(APP_KIND_STATE)

  const setAppKind = useCallback(
    kind => {
      dispatch(APP_KIND_ACTIONS.SET, kind)
    },
    [dispatch],
  )

  return { appKind: state.appKind, setAppKind }
}

export { appKindModule, useAppKind }
