import { useMemo } from 'react'

import map from 'lodash/map'

import { USER_ROLE } from 'Constants/ids'

import _ from 'Services/I18n'

export default function useUserRolesOptions() {
  return useMemo(
    () =>
      map(Object.values(USER_ROLE), role => ({
        label: _(`userRoles.${role}`),
        value: role,
      })),
    [],
  )
}
