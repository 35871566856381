import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import Tab from '@mui/material/Tab'

import noop from 'lodash/noop'

import { Container } from './styles'

function Tabs({ currentValue, options, onChange, ...rest }) {
  const handleChangeTab = useCallback(
    (_, nextTab) => {
      onChange(nextTab)
    },
    [onChange],
  )

  const tabContent = useCallback(
    option => (option.value === currentValue ? option.children : null),
    [currentValue],
  )

  return (
    <>
      <Container
        {...rest}
        textColor="secondary"
        value={currentValue}
        onChange={handleChangeTab}
      >
        {options.map(option => (
          <Tab key={option.value} label={option.label} value={option.value} />
        ))}
      </Container>

      {options.map(tabContent)}
    </>
  )
}

Tabs.propTypes = {
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired,
      children: PropTypes.node.isRequired,
    }),
  ),
  onChange: PropTypes.func,
}

Tabs.defaultProps = {
  currentValue: undefined,
  options: [],
  onChange: noop,
}

export default Tabs
