import { DateTime } from 'luxon'

import upperFirst from 'lodash/upperFirst'

import { dcLogoImage, defaultPurpleLogoImage } from 'Assets/Img'

import { LANGUAGE } from 'Constants/ids'
import { ROOM_KIND } from 'Constants/room'

import roomChannelQuery from 'GraphQL/Queries/Chat/roomChannel.graphql'

import { getApolloClient } from 'Services/Shared'

export function getRoomUpdateTime(update) {
  DateTime.fromISO(update?.createdAt).toFormat('h:mm a')
}

export function dateToLocale(date, locale = LANGUAGE.EN) {
  const todayDT = DateTime.local()
  const yesterdayDT = todayDT.minus({ day: 1 })
  const createdDT = DateTime.fromISO(date)

  if (
    createdDT.hasSame(todayDT, 'day') ||
    createdDT.hasSame(yesterdayDT, 'day')
  ) {
    return upperFirst(createdDT.toRelativeCalendar({ locale }))
  }

  return createdDT.toLocaleString(DateTime.DATE_MED)
}

export function isSameDay(item, nextItem) {
  if (!item || !nextItem) return false

  return DateTime.fromISO(item.createdAt).hasSame(
    DateTime.fromISO(nextItem.createdAt),
    'day',
  )
}

export function isSharedUpdate(update) {
  return (update?.heirsCount ?? 0) >= 1
}

export function isSharedUpdateChild(update) {
  if (!update?.original) {
    return false
  }

  return isSharedUpdate(update.original)
}

export function getRoomDefaultAvatar(kind) {
  return {
    [ROOM_KIND.UNIVERSAL]: dcLogoImage,
    [ROOM_KIND.PURPLE]: defaultPurpleLogoImage,
  }[kind || ROOM_KIND.UNIVERSAL]
}

export async function getRoomChannel(variables) {
  const result = await getApolloClient().query({
    query: roomChannelQuery,
    variables,
    context: { chat: true },
  })

  return result.data?.roomChannel
}
