import styled from 'styled-components'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${layout.width}
  ${margin}
`
