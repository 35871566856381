import palette from './palette'

const selects = {
  height: {
    default: 40,
  },
  background: {
    disabled: palette.grayLightest,
    default: palette.white,
  },
  border: {
    withError: palette.negative,
    default: palette.gray,
  },
  focus: {
    border: {
      default: palette.grayDark,
    },
  },
}

export default selects
