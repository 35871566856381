export const breakpoints = [32, 48, 64, 80]

//                    0  1  2  3   4   5   6   7   8
export const space = [0, 4, 8, 12, 16, 24, 32, 64, 128]

//                        0   1   2   3   4   5   6   7   8   9
export const fontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 72, 96]

export const weights = [400, 700]

export const colors = {
  black: '#000',
  white: '#fff',
}

export const radius = 4
export const font = `-apple-system, BlinkMacSystemFont, sans-serif`
export const monospace = 'Menlo, monospace'

const defaultTheme = {
  breakpoints,
  space,
  fontSizes,
  weights,
  font,
  monospace,
  colors,
  radius,
}

export default defaultTheme
