import React, { useCallback, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'

import { FiXCircle } from 'react-icons/fi'

import noop from 'lodash/noop'
import pick from 'lodash/pick'
import replace from 'lodash/replace'
import toNumber from 'lodash/toNumber'

import { Column } from 'Components/UI/Flex'
import Text from 'Components/UI/Text'

import { theme } from 'Theme'

import { CloseButton, StyledModal } from './styles'

ReactModal.setAppElement('body')

const TIMEOUT = toNumber(replace(theme?.transitionTime?.modal, 'ms', ''))

function Modal({
  children,
  isOpen,
  shouldCloseOnOverlayClick,
  title,
  onClose,
  onMount,
}) {
  const [internalOpen, setInternalOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setInternalOpen(true)
    }
  }, [isOpen])

  const handleClose = useCallback(() => {
    setInternalOpen(false)
  }, [])

  useEffect(() => {
    onMount({ handleClose })
  }, [handleClose, onMount])

  return (
    <StyledModal
      closeTimeoutMS={TIMEOUT}
      isOpen={internalOpen}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onAfterClose={onClose}
      onRequestClose={handleClose}
    >
      <Column center pt={4} px={4}>
        <Text bolder fontSize={18}>
          {title}
        </Text>
        <CloseButton onClick={handleClose}>
          <FiXCircle />
        </CloseButton>
      </Column>

      {children}
    </StyledModal>
  )
}

Modal.defaultProps = {
  children: undefined,
  isOpen: false,
  shouldCloseOnOverlayClick: true,
  title: null,
  onClose: noop,
  onMount: noop,
}

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.objectOf(PropTypes.node),
  ]),
  isOpen: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onMount: PropTypes.func,
}

const PROPS = [
  'isOpen',
  'shouldCloseOnOverlayClick',
  'title',
  'onClose',
  'onMount',
]

Modal.pickProps = props => pick(props, PROPS)

export default Modal
