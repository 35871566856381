import get from 'lodash/get'
import trim from 'lodash/trim'

import t from 'Services/I18n'

function singleSpaces(str) {
  return str.replace(/  +/g, ' ')
}

function formatFullName(profile, fallback = 'Incognito') {
  const firstName = get(profile, 'firstName', '')
  const lastName = get(profile, 'lastName', '')
  const displayName = get(profile, 'displayName', '')

  if (displayName) return displayName

  if (firstName && lastName) {
    return trim(singleSpaces(`${firstName} ${lastName}`))
  }

  return fallback
}

function anonymizeUserIdentity(identifier) {
  const firstLetterIdentifier = identifier?.[0]
  const lastLetterIdentifier = identifier?.substring(identifier?.length - 1)

  return `${firstLetterIdentifier || ''}****${lastLetterIdentifier || ''}`
}

function getParticipantRole(role) {
  return t(`userRoles.${role}`, {
    defaults: [{ scope: 'userRoles.unknown' }],
  })
}

export { anonymizeUserIdentity, formatFullName, getParticipantRole }
