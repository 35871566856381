import { DateTime } from 'luxon'

import * as Strings from './Strings'

export function getIsPm(date) {
  return !!date.toLocaleString(DateTime.TIME_SIMPLE).match(/pm/i)
}

export function localeWithMeridiem() {
  return !!DateTime.local().toLocaleString(DateTime.TIME_SIMPLE).match(/am|pm/i)
}

export function secondsToHms(timeInSeconds) {
  const time = parseFloat(timeInSeconds).toFixed(3)
  const hours = Math.floor(time / 60 / 60)
  const minutes = Math.floor(time / 60) % 60

  return `${Strings.zeroPad(hours, 2)}:${Strings.zeroPad(minutes, 2)}`
}

export function hmsToSeconds(str) {
  const [hh = '0', mm = '0', ss = '0'] = (str || '0:0:0').split(':')
  const hour = parseInt(hh, 10) || 0
  const minute = parseInt(mm, 10) || 0
  const second = parseInt(ss, 10) || 0
  return hour * 3600 + minute * 60 + second
}

export function isSameDay(dayA, dayB) {
  if (!dayA || !dayB) {
    return false
  }

  return dayA.hasSame(dayB, 'day')
}
