import palette from './palette'

const colors = {
  ...palette,

  primary: palette.primary,
  white: palette.white,
  black: palette.black,
  positive: palette.positive,
  negative: palette.negative,
  warning: palette.warning,
  secondaryBlack: palette.secondaryBlack,
  disabled: palette.gray,

  bg: {
    lightPrimary: palette.white,
    secondary: palette.grayDark,
    default: palette.grayLightest,
  },

  border: {
    tertiary: palette.smoke,
    secondary: palette.smokeLight,
    default: palette.grayLight,
  },

  font: {
    secondary: palette.gray,
    default: palette.grayDark,
    link: palette.dodgerBlue,
  },
}

export default colors
