import { RiLoader4Line } from 'react-icons/ri'

import styled, { keyframes } from 'styled-components'
import { layout } from '@styled-system/layout'

const animation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const AnimatedIcon = styled(RiLoader4Line)`
  animation: ${animation} 1.75s infinite linear;

  ${layout.size}
`

export const Text = styled.p`
  margin-top: 4px;
`
