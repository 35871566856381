import React from 'react'

import { DateTime } from 'luxon'

import startCase from 'lodash/startCase'

import { LONG_DASH } from 'Constants/strings'

export function renderDateTimeCell({ value }) {
  if (value) {
    return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT)
  }
  return LONG_DASH
}

export function renderOptionalString({ value }) {
  if (!value) {
    return LONG_DASH
  }
  return value
}

export function createBooleanCellRenderer({ render: ComponentToRender }) {
  // eslint-disable-next-line react/prop-types
  return ({ value }) => <ComponentToRender checked={value} readOnly />
}

export function renderStartCaseCell({ value }) {
  return renderOptionalString({ value: startCase(value) })
}

export function renderDoubleCell({ value }) {
  return renderOptionalString({ value: value?.toFixed(2) })
}

export function renderUpperCaseCell({ value }) {
  return renderOptionalString({ value: value?.toUpperCase() })
}
