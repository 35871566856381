import styled from 'styled-components'
import styledMap from 'styled-map'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-height: 48px;
  border-radius: 12px;
  background-color: ${themeGet('colors.bg.default')};
  padding: 8px 16px 8px 32px;
`

export const Dot = styled.div`
  position: absolute;
  top: 14px;
  left: 14px;
  width: 8px;
  height: 8px;
  border-radius: 4px;

  background: ${styledMap('type', {
    info: themeGet('colors.primary'),
    error: themeGet('colors.negative'),
    success: themeGet('colors.positive'),
  })};
`
