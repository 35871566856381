import React from 'react'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'

import { BiBuilding, BiBuildings, BiShield, BiUser } from 'react-icons/bi'
import { FiRefreshCw, FiUsers } from 'react-icons/fi'
import { HiOutlineTemplate } from 'react-icons/hi'
import { RiDashboardLine } from 'react-icons/ri'

import Header from 'Components/Blocks/Admin/Header'
import { Column, Link, Logo, Row } from 'Components/UI'

import { APP_NAME } from 'Config'

import {
  ADMIN_ADMINS,
  ADMIN_DASHBOARD,
  ADMIN_DIRECTORIES,
  ADMIN_ORGANIZATIONS,
  ADMIN_ROOM_SPECS,
  ADMIN_ROOMS,
  ADMIN_ROOT,
  ADMIN_TEMPLATES,
  ADMIN_USERS,
  UPDATE_APP,
} from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'

import {
  AppName,
  Container,
  Content,
  DirectoriesIcon,
  Menu,
  MenuLink,
  PageContent,
} from './styles'

const Admin = ({ route }) => {
  const s = useScopedI18n('layout.admin')
  return (
    <Container>
      <Menu>
        <Row center fullWidth height={48} px={1}>
          <Link to={ADMIN_ROOT}>
            <Logo size={32} />
            <AppName ml={2}>{APP_NAME}</AppName>
          </Link>
        </Row>
        <Column mt={3}>
          <MenuLink to={ADMIN_ADMINS(1)}>
            <BiShield /> {s('admins')}
          </MenuLink>
          <MenuLink to={ADMIN_USERS(1)}>
            <BiUser /> {s('users')}
          </MenuLink>
          <MenuLink to={ADMIN_ROOM_SPECS(1)}>
            <BiBuilding /> {s('roomSpecs')}
          </MenuLink>
          <MenuLink to={ADMIN_ORGANIZATIONS(1)}>
            <BiBuildings /> {s('organizations')}
          </MenuLink>
          <MenuLink to={ADMIN_DIRECTORIES}>
            <DirectoriesIcon /> {s('directories')}
          </MenuLink>
          <MenuLink to={ADMIN_DASHBOARD(1)}>
            <RiDashboardLine /> {s('dashboard')}
          </MenuLink>
          <MenuLink to={ADMIN_ROOMS(1)}>
            <FiUsers /> {s('rooms')}
          </MenuLink>
          <MenuLink to={ADMIN_TEMPLATES(1)}>
            <HiOutlineTemplate /> {s('templates')}
          </MenuLink>
          <MenuLink to={UPDATE_APP}>
            <FiRefreshCw />
            {s('updateApp')}
          </MenuLink>
        </Column>
      </Menu>
      <Content>
        <Header />
        <PageContent>{renderRoutes(route.routes)}</PageContent>
      </Content>
    </Container>
  )
}

Admin.propTypes = {
  route: PropTypes.object.isRequired,
}

export default Admin
