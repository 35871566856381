import { useCallback, useMemo, useState } from 'react'

import debounce from 'lodash/debounce'

export default function useTableSearch({ onSearch }) {
  const [search, setSearch] = useState('')

  const debouncedSearch = useMemo(
    () =>
      debounce(value => {
        setSearch(value)
        onSearch(value)
      }, 700),
    [onSearch],
  )

  const handleChangeSearch = useCallback(
    event => {
      const { value } = event.target
      debouncedSearch(value)
    },
    [debouncedSearch],
  )

  return [search, handleChangeSearch]
}
