import { useSubscription } from '@apollo/client'

import roomUpdatedSubscription from 'GraphQL/Subscriptions/roomUpdated.graphql'
import { roomUpdated } from 'GraphQL/Updaters/Room'

import { useLocationSearchParams } from 'Hooks'

function Room() {
  const searchParams = useLocationSearchParams()
  const roomId = searchParams.get('id')

  useSubscription(roomUpdatedSubscription, {
    variables: { roomId },
    shouldResubscribe: true,
    skip: !roomId,
    onSubscriptionData({ client, subscriptionData }) {
      if (!subscriptionData.data?.roomUpdated) return

      roomUpdated(client, subscriptionData.data.roomUpdated)
    },
  })
  return null
}

export default Room
