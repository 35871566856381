import React from 'react'
import PropTypes from 'prop-types'

import { Column } from 'Components/UI/Flex'

import { AnimatedIcon, Text } from './styles'

function Loader({ text, fullHeight, fullScreen, fullWidth, size, ...rest }) {
  return (
    <Column
      {...rest}
      center
      fullHeight={fullHeight}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      justifyCenter
      squeeze={!fullWidth}
    >
      <AnimatedIcon size={size} />
      {!!text && <Text>{text}</Text>}
    </Column>
  )
}

Loader.defaultProps = {
  fullHeight: undefined,
  fullScreen: undefined,
  fullWidth: undefined,
  size: 24,
  text: undefined,
}

Loader.propTypes = {
  fullHeight: PropTypes.bool,
  fullScreen: PropTypes.bool,
  fullWidth: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  text: PropTypes.string,
}

export default Loader
