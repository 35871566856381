import { useEffect, useMemo, useRef } from 'react'

export default function useTableSort({ refetch, sort, ...rest }) {
  const variables = useMemo(() => rest ?? {}, [rest])

  const prevSort = useRef(sort)

  useEffect(() => {
    if (prevSort.current !== sort) {
      refetch({
        sort,
        ...variables,
      })

      prevSort.current = sort
    }
  }, [refetch, sort, variables])
}
