import styled, { css } from 'styled-components'
import { layout, margin } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import MaterialIconButton from '@mui/material/IconButton'

const iconButtonDisabledCss = ({ disabled }) =>
  disabled &&
  css`
    background-color: ${themeGet('colors.disabled')};
  `

const IconButton = styled(MaterialIconButton)`
  &.MuiIconButton-root {
    ${margin}
    ${layout}
    ${iconButtonDisabledCss}
  }
`

export default IconButton
