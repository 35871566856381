export const APP_KIND = {
  ADVOCATE: 'advocate',
  ADMIN: 'admin',
}

export const MOBILE_APP_KIND = {
  DC: 'dc',
  SOS: 'sos',
  KAPUA: 'kapua',
  UW: 'uw',
}

export const APP_THEME = {
  MAIN: 'main',
  PORTAL: 'portal',
  DEFAULT: 'default',
}

export const TERMS_AND_CONDITIONS_URL = 'https://www.ohanalink.com/terms'
