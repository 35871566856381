import { messagesCursorMerge, offsetMerge } from './utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Query: {
    fields: {
      userRooms: {
        keyArgs: ['name', 'folders', 'showDeactivated'],
        merge: (existing, incoming, { args }) =>
          offsetMerge(existing, incoming, args?.page),
        read: existing => existing,
      },
      headquarters: {
        keyArgs: ['name', 'search'],
        merge: (existing, incoming, { args }) =>
          offsetMerge(existing, incoming, args?.page),
        read: existing => existing,
      },
      organizations: {
        keyArgs: ['name', 'search', 'headquarterId'],
        merge: (existing, incoming, { args }) =>
          offsetMerge(existing, incoming, args?.page),
        read: existing => existing,
      },
      moreMessages: {
        keyArgs: ['channelId', 'first'],
        merge: (existing, incoming, { args }) =>
          messagesCursorMerge(existing, incoming, args?.after),
        read: existing => existing,
      },
    },
    UserProfile: {
      keyFields: ['userId'],
    },
  },
}
