import { USER_ROLE } from 'Constants/ids'

import { ADMIN_DIRECTORIES, PORTAL_ROOT, ROOT } from 'Router/routes'

export function getRootRoute(user) {
  switch (user?.role) {
    case USER_ROLE.ADMIN:
    case USER_ROLE.SUPER_ADMIN:
      return ADMIN_DIRECTORIES
    case USER_ROLE.USER:
      return PORTAL_ROOT
    default:
      return ROOT
  }
}
