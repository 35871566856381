import React, { useCallback } from 'react'
import { Field, useForm } from 'react-final-form'
import PropTypes from 'prop-types'

import Utils from 'Utils'

import Input, { TextArea } from './Input'

function InputField({
  checkErrorIfDirty,
  displayError,
  input,
  name,
  validate,
  ...rest
}) {
  const formApi = useForm()

  const handleClear = useCallback(() => {
    formApi.change(name, null)
  }, [formApi, name])

  return (
    <Field name={name} validate={validate}>
      {({ input: { value, onChange: handleChange }, meta }) => {
        const { hasError, error } = Utils.Form.hasError(meta, checkErrorIfDirty)

        return input?.type === 'textarea' ? (
          <TextArea
            error={displayError && hasError && error}
            value={value}
            {...input}
            {...rest}
            onChange={handleChange}
          />
        ) : (
          <Input
            error={displayError && hasError && error}
            value={value}
            {...input}
            {...rest}
            onChange={handleChange}
            onClear={handleClear}
          />
        )
      }}
    </Field>
  )
}

InputField.defaultProps = {
  checkErrorIfDirty: false,
  displayError: true,
  input: null,
  validate: null,
}

InputField.propTypes = {
  checkErrorIfDirty: PropTypes.bool,
  displayError: PropTypes.bool,
  input: PropTypes.shape(Input.propTypes),
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
}

export default InputField
