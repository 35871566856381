import React from 'react'
import PropTypes from 'prop-types'

import { omit, pick } from '@styled-system/props'

import Text from 'Components/UI/Text'

import { Background, Container, Icon, TextContent } from './styles'

function Checkbox({ checked, disabled, error, label, meta, ...rest }) {
  return (
    <Container {...pick(rest)}>
      <input
        checked={checked}
        disabled={disabled}
        type="checkbox"
        {...omit(rest)}
      />
      <Background />
      <Icon />
      <TextContent ml={2}>
        {label && <Text>{label}</Text>}
        {error && (
          <Text negative small>
            {error}
          </Text>
        )}
      </TextContent>
    </Container>
  )
}

Checkbox.defaultProps = {
  checked: null,
  disabled: false,
  error: null,
  label: null,
  meta: {},
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  meta: PropTypes.object,
}

export default Checkbox
