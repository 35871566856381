import styled from 'styled-components'
import { margin } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import Paper from '@mui/material/Paper'

const ListItemContainer = styled(Paper)`
  background-color: ${themeGet('colors.bg.lightPrimary')};
  padding: ${themeGet('space.4')}px;

  ${margin}
`

export default ListItemContainer
