import { useMemo } from 'react'
import { useFlexLayout, usePagination, useSortBy, useTable } from 'react-table'

export default function useEntityTable({ columns, data, ...rest }) {
  const dataRows = data?.rows
  const pageCount = data?.pages ?? 0
  const rowsCount = data?.count ?? 0

  const tableData = useMemo(() => dataRows ?? [], [dataRows])

  const tableProps = useTable(
    {
      autoResetPage: false,
      autoResetSortBy: false,
      columns,
      data: tableData,
      manualPagination: true,
      manualSortBy: true,
      pageCount,
      ...rest,
    },
    useFlexLayout,
    useSortBy,
    usePagination,
  )

  return [tableProps, rowsCount]
}
