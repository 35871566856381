import { useSubscription } from '@apollo/client'

import channelCreatedSubscription from 'GraphQL/Subscriptions/Chat/channelCreated.graphql'
import channelDeletedSubscription from 'GraphQL/Subscriptions/Chat/channelDeleted.graphql'
import unreadUpdatedSubscription from 'GraphQL/Subscriptions/Chat/unreadCountersUpdated.graphql'
import {
  channelCreated,
  channelDeleted,
  channelUnreadUpdated,
} from 'GraphQL/Updaters/Chat/Channel'

function ChatChannelSubscriptionManager() {
  useSubscription(channelCreatedSubscription, {
    shouldResubscribe: true,
    onSubscriptionData({ client, subscriptionData }) {
      if (!subscriptionData.data?.channelCreated) return

      channelCreated({
        client,
        newChannel: subscriptionData.data.channelCreated,
      })
    },
  })

  useSubscription(channelDeletedSubscription, {
    shouldResubscribe: true,
    onSubscriptionData({ client, subscriptionData }) {
      if (!subscriptionData.data?.channelDeleted) return

      channelDeleted({ client, channel: subscriptionData.data.channelDeleted })
    },
  })

  useSubscription(unreadUpdatedSubscription, {
    shouldResubscribe: true,
    onSubscriptionData({ client, subscriptionData }) {
      if (!subscriptionData.data?.unreadCountersUpdated) return

      channelUnreadUpdated({
        client,
        unreadCounter: subscriptionData.data.unreadCountersUpdated,
      })
    },
  })

  return null
}

export default ChatChannelSubscriptionManager
