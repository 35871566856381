import { APP_THEME } from 'Constants/app'

import buttons from './buttons'
import colors from './colors'
import inputs from './inputs'
import muiTheme from './muiTheme'
import selects from './selects'
import switches from './switches'
import text from './text'

import defaultTheme from '../defaultTheme'

const theme = {
  ...defaultTheme,

  name: APP_THEME.MAIN,
  font: 'Roboto, Arial, Helvetica, sans-serif;',
  webfonts: ['Roboto:400,600,700,900'],

  transitionTime: {
    default: '250ms',
    modal: '150ms',
  },

  colors,

  selects,
  switches,
  buttons,
  inputs,
  text,

  muiTheme,
}

export default theme
