import React from 'react'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'

import { Container, PageContent } from './styles'

function PortalLayout({ route }) {
  return (
    <Container>
      <PageContent>{renderRoutes(route.routes)}</PageContent>
    </Container>
  )
}

PortalLayout.propTypes = {
  route: PropTypes.object.isRequired,
}

export default PortalLayout
