import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import Tippy from '@tippyjs/react/headless'

import isFunction from 'lodash/isFunction'

import { Arrow, PopoverBlock } from './styles'

function Popover({ content, offset, plugins, render, withArrow, ...rest }) {
  const [mounted, setMounted] = useState(false)
  const [arrow, setArrow] = useState(null)

  const lazyPlugin = {
    fn: () => ({
      onShow: () => setMounted(true),
      onHidden: () => setMounted(false),
    }),
  }

  const computedProps = { content, offset, plugins, render, ...rest }

  computedProps.plugins = [lazyPlugin].concat(plugins || [])

  computedProps.render = attrs =>
    mounted ? (
      <PopoverBlock {...pick(rest)} {...attrs}>
        {isFunction(render) ? render(attrs) : content}
        {withArrow && <Arrow ref={setArrow} />}
      </PopoverBlock>
    ) : (
      ''
    )

  return (
    <Tippy
      popperOptions={{
        modifiers: [
          {
            name: 'arrow',
            options: {
              element: arrow,
            },
          },
          {
            name: 'flip',
            options: {
              padding: { top: 64 },
            },
          },
        ],
      }}
      {...computedProps}
    />
  )
}

Popover.delay = [270, 0]

Popover.defaultProps = {
  content: '',
  offset: [0, 8],
  plugins: [],
  render: null,
  withArrow: false,
}

Popover.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  offset: PropTypes.arrayOf(PropTypes.number),
  plugins: PropTypes.array,
  render: PropTypes.func,
  withArrow: PropTypes.bool,
}

export default Popover
