import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { overflow } from 'styled-system'
import { border } from '@styled-system/border'
import { color } from '@styled-system/color'
import { flexbox } from '@styled-system/flexbox'
import { layout } from '@styled-system/layout'
import { margin, padding, space } from '@styled-system/space'

export default styled.div`
  display: flex;

  ${border}
  ${color}
  ${flexbox}
  ${overflow}
  ${space}
  ${layout}
`

const stretchCss = ({ stretch }) =>
  stretch &&
  css`
    flex: 1;
  `

const alignSelfStartCss = ({ alignSelfStart }) =>
  alignSelfStart &&
  css`
    align-self: flex-start;
  `

const alignSelfEndCss = ({ alignSelfEnd }) =>
  alignSelfEnd &&
  css`
    align-self: flex-end;
  `

const alignEndCss = ({ alignEnd }) =>
  alignEnd &&
  css`
    align-items: flex-end;
  `

const borderBottomCss = ({ borderBottom }) =>
  borderBottom &&
  css`
    border-bottom: solid 1px ${mapToTheme('colors.border')};
  `

const borderTopCss = ({ borderTop }) =>
  borderTop &&
  css`
    border-top: solid 1px ${mapToTheme('colors.border')};
  `

const centerCss = ({ center }) =>
  center &&
  css`
    align-items: center;
  `

const fullHeightCss = ({ fullHeight }) =>
  fullHeight &&
  css`
    height: 100%;
  `

const fullScreenCss = ({ fullScreen }) =>
  fullScreen &&
  css`
    width: 100vw;
    height: 100vh;
  `

const fullWidthCss = ({ fullWidth }) =>
  fullWidth &&
  css`
    width: 100%;
  `

const justifyCenterCss = ({ justifyCenter }) =>
  justifyCenter &&
  css`
    justify-content: center;
  `

const justifyEndCss = ({ justifyEnd }) =>
  justifyEnd &&
  css`
    justify-content: flex-end;
  `

const noShrinkCss = ({ noShrink }) =>
  noShrink &&
  css`
    flex-shrink: 0;
  `

const spaceBetweenCss = ({ spaceBetween }) =>
  spaceBetween &&
  css`
    justify-content: space-between;
  `

const squeezeCss = ({ squeeze }) =>
  squeeze &&
  css`
    width: fit-content;
  `

const isPointerCss = ({ isPointer }) =>
  isPointer &&
  css`
    cursor: pointer;
  `

const DEFAULT_PROPS = {
  alignEnd: undefined,
  alignSelfEnd: false,
  alignSelfStart: false,
  borderBottom: undefined,
  borderTop: undefined,
  center: undefined,
  fullHeight: undefined,
  fullScreen: undefined,
  fullWidth: undefined,
  isPointer: false,
  justifyCenter: undefined,
  justifyEnd: undefined,
  noShrink: undefined,
  spaceBetween: undefined,
  squeeze: undefined,
  stretch: undefined,
}

const PROP_TYPES = {
  alignEnd: PropTypes.bool,
  alignSelfEnd: PropTypes.bool,
  alignSelfStart: PropTypes.bool,
  borderBottom: PropTypes.bool,
  borderTop: PropTypes.bool,
  center: PropTypes.bool,
  fullHeight: PropTypes.bool,
  fullScreen: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isPointer: PropTypes.bool,
  justifyCenter: PropTypes.bool,
  justifyEnd: PropTypes.bool,
  noShrink: PropTypes.bool,
  spaceBetween: PropTypes.bool,
  squeeze: PropTypes.bool,
  stretch: PropTypes.bool,
}

export const Element = styled.div`
  ${layout}
  ${margin}
  ${padding}
  ${flexbox}
  ${color}
  ${border}

  ${alignEndCss}
  ${alignSelfEndCss}
  ${alignSelfStartCss}
  ${borderBottomCss}
  ${borderTopCss}
  ${centerCss}
  ${fullHeightCss}
  ${fullWidthCss}
  ${isPointerCss}
  ${justifyCenterCss}
  ${justifyEndCss}
  ${noShrinkCss}
  ${spaceBetweenCss}
  ${stretchCss}
  ${squeezeCss}
  ${fullScreenCss}
`

export const Column = styled(Element)`
  display: flex;
  flex-direction: column;
`

Column.defaultProps = DEFAULT_PROPS
Column.propTypes = PROP_TYPES

export const Row = styled(Element)`
  display: flex;
`

Row.defaultProps = DEFAULT_PROPS
Row.propTypes = PROP_TYPES
