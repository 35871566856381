import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { layout } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import {
  dcLogoImage,
  kapuaLogoImage,
  sosLogoImage,
  uwLogoImage,
} from 'Assets/Img'

import { MOBILE_APP_KIND } from 'Constants/app'

const containerInverseCss = ({ inverse }) =>
  inverse &&
  css`
    background-color: ${themeGet('colors.bg.secondary')};
  `

export const Container = styled.div`
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  background-color: ${themeGet('colors.bg.default')};
  border-radius: 50%;
  display: flex;

  ${layout.size}
  ${layout.width}
  ${layout.height}

  ${containerInverseCss}
`

const logoIconInverseCss = ({ inverse }) =>
  inverse &&
  css`
    color: ${themeGet('colors.bg.default')};
  `

export const LogoIcon = styled.div`
  color: ${themeGet('colors.bg.secondary')};
  display: flex;
  flex: 1;
  align-self: center;
  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;

  background-image: ${styledMap('kind', {
    [MOBILE_APP_KIND.DC]: `url(${dcLogoImage})`,
    [MOBILE_APP_KIND.KAPUA]: `url(${kapuaLogoImage})`,
    [MOBILE_APP_KIND.SOS]: `url(${sosLogoImage})`,
    [MOBILE_APP_KIND.UW]: `url(${uwLogoImage})`,
  })};

  ${logoIconInverseCss}
`
