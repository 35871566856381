export const ROOT = `/`

export const REDIRECTOR = `${ROOT}redirector`

export const APP_ROOT = `${ROOT}app`
export const APP_PROFILE = `${APP_ROOT}/profile`

export const AUTH_ROOT = `${ROOT}auth`
export const AUTH_SIGN_UP = `${AUTH_ROOT}/sign-up`
export const AUTH_REQUEST_CHANGE_PASSWORD = `${AUTH_ROOT}/request-change-email-password`
export const AUTH_CHANGE_PASSWORD = `${AUTH_ROOT}/change-email-password`

export const AUTH_CONFIRM_EMAIL = (kind = '') =>
  `${kind ? `/${kind}` : ''}${AUTH_ROOT}/confirm-email`

export const SOCIAL_CALLBACK = social =>
  `${ROOT}callback/${social || ':social'}`

export const SIGN_OUT = `${ROOT}sign-out`

export const ADMIN_ROOT = `${ROOT}admin`

export const ADMIN_ADMINS = page => `${ADMIN_ROOT}/admins/${page ?? ':page'}`
export const ADMIN_USERS = page => `${ADMIN_ROOT}/users/${page ?? ':page'}`
export const ADMIN_USER_PROFILE = id =>
  `${ADMIN_ROOT}/users/profile/${id ?? ':id'}`

export const ADMIN_ROOM_SPECS = page =>
  `${ADMIN_ROOT}/room-specs/${page ?? ':page'}`
export const ADMIN_ROOM_SPECS_PROFILE = id =>
  `${ADMIN_ROOT}/room-specs/profile/${id ?? ':id'}`

export const ADMIN_ORGANIZATIONS = page =>
  `${ADMIN_ROOT}/organizations/${page ?? ':page'}`
export const ADMIN_ORGANIZATIONS_PROFILE = (id, page) =>
  `${ADMIN_ROOT}/organizations/profile/${id ?? ':id'}/${page ?? ':page'}`

export const ADMIN_ROOMS = page => `${ADMIN_ROOT}/rooms/${page ?? ':page'}`
export const ADMIN_ROOM_PROFILE = id =>
  `${ADMIN_ROOT}/rooms/profile/${id ?? ':id'}`

export const ADMIN_DASHBOARD = page =>
  `${ADMIN_ROOT}/dashboards/${page ?? ':page'}`
export const ADMIN_DASHBOARD_PROFILE = id =>
  `${ADMIN_ROOT}/dashboards/profile/${id ?? ':id'}`

export const ADMIN_DIRECTORIES = `${ADMIN_ROOT}/directories`
export const ADMIN_DIRECTORY_PROFILE = (id, page) =>
  `${ADMIN_ROOT}/directories/profile/${id ?? ':id'}/${page ?? ':page'}`

export const ADMIN_TEMPLATES = page =>
  `${ADMIN_ROOT}/templates/${page ?? ':page'}`
export const ADMIN_TEMPLATE_PROFILE = id =>
  `${ADMIN_ROOT}/templates/profile/${id ?? ':id'}`

export const ADMIN_CONTACT_PROFILE = (headquarterId, id) =>
  `${ADMIN_ROOT}/organization/profile/${headquarterId ?? ':headquarterId'}/${
    id ?? ':id'
  }`

export const UPDATE_APP = `${ADMIN_ROOT}/update-app`
export const UPDATE_APP_CONFIGURATION = `${UPDATE_APP}/update-app-configuration`

export const TERMS_OF_SERVICE = `${ROOT}terms`

export const UI_KIT = `${ROOT}ui`

export const MOBILE_INVITATION = (kind = '') =>
  `${kind ? `/${kind}` : ''}${ROOT}invitation`

export const MOBILE_ROOM_INVITATION = nanoid =>
  `${ROOT}s/${nanoid ?? ':nanoid'}`

export const NOT_FOUND = `${ROOT}404`

export const PORTAL_ROOT = `${ROOT}portal`

export const PORTAL_PROFILE = `${PORTAL_ROOT}/profile`
export const PORTAL_CONFIRM_EMAIL = `${PORTAL_ROOT}/confirm-email`
export const PORTAL_ROOM = `${PORTAL_ROOT}/room`
