import { FiCalendar } from 'react-icons/fi'

import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { text } from 'Components/Styles'
import { Link } from 'Components/UI'

export const Container = styled.div.attrs({
  as: 'section',
})`
  display: flex;
  background: rgb(238, 198, 198);
  background: linear-gradient(
    180deg,
    rgba(238, 198, 198, 1) 0%,
    rgba(218, 163, 189, 1) 100%
  );
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
`

export const AppName = styled.div`
  ${text(18)};
  color: ${themeGet('colors.white')};
  font-weight: 900;
  ${margin}
`

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 100%;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const PageContent = styled.div`
  background: ${themeGet('colors.white')};
  border-top-left-radius: 24px;
  overflow: scroll;
  padding-left: ${themeGet('space.4')}px;
  flex: 1;
`

export const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 8px;
  text-decoration: none;
  ${text(16)}
  color: ${themeGet('colors.white')};
  &.active {
    font-weight: bold;
  }
  > svg {
    margin-right: 4px;
  }
`

export const DirectoriesIcon = styled(FiCalendar)`
  transform: rotate(-90deg);
`
