import React from 'react'
import PropTypes from 'prop-types'

import { omit, pick } from '@styled-system/props'

import { Container, HiddenSwitch, StyledSwitch } from './styles'

function Switch(props) {
  const { disabled, width, height, className, ...rest } = props

  return (
    <Container
      {...pick(rest)}
      className={className}
      disabled={disabled}
      height={height}
      width={width}
    >
      <HiddenSwitch disabled={disabled} {...omit(rest)} />
      <StyledSwitch disabled={disabled} />
    </Container>
  )
}

Switch.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
}

Switch.defaultProps = {
  className: undefined,
  disabled: false,
  height: 24,
  width: 46,
}

export default Switch
