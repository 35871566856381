import { useSubscription } from '@apollo/client'

import emojiAddedSubscription from 'GraphQL/Subscriptions/Chat/emojiAdded.graphql'
import emojiRemovedSubscription from 'GraphQL/Subscriptions/Chat/emojiRemoved.graphql'
import { emojiAdded, emojiRemoved } from 'GraphQL/Updaters/Chat/Emoji'

import { useLocationSearchParams } from 'Hooks'

function ChatEmojiSubscriptionManager() {
  // TODO: NOT the best solution but needs to know channelId from somewhere cause
  // channelId is required param in subscriptions above
  const queryParams = useLocationSearchParams()

  useSubscription(emojiRemovedSubscription, {
    shouldResubscribe: true,
    variables: { channelId: queryParams.get('channelId') },
    onSubscriptionData({ client, subscriptionData }) {
      if (!subscriptionData.data?.emojiRemoved) return

      emojiRemoved({ client, emoji: subscriptionData.data.emojiRemoved })
    },
  })

  useSubscription(emojiAddedSubscription, {
    shouldResubscribe: true,
    variables: { channelId: queryParams.get('channelId') },
    onSubscriptionData({ client, subscriptionData }) {
      if (!subscriptionData.data?.emojiAdded) return

      emojiAdded({ client, emoji: subscriptionData.data.emojiAdded })
    },
  })

  return null
}

export default ChatEmojiSubscriptionManager
