import merge from 'lodash/merge'

import { FragmentOptions } from 'GraphQL/Updaters/utils'

function roomUpdated(client, room) {
  if (!room?.id) return

  const fragmentData = FragmentOptions.room(room.id)
  const data = client.readFragment(fragmentData)
  if (!data) return

  client.writeFragment({
    ...fragmentData,
    data: merge({}, data, room),
  })
}

export { roomUpdated }
