import React from 'react'

import { FiLogOut } from 'react-icons/fi'

import Avatar from 'Components/Blocks/Avatar'
import { Column, Dropdown, Flex, Text } from 'Components/UI'

import { ROLE_NAMES } from 'Constants/strings'

import { useAppContext } from 'Hooks'

import { SIGN_OUT } from 'Router/routes'

import { Container } from './styles'

function Header() {
  const { me } = useAppContext()

  return (
    <Container center height={48} justifyEnd noShrink px={3}>
      <Column>
        <Text bold textAlign="right" white>
          {me?.profile?.firstName || ''} {me?.profile?.lastName}
        </Text>
        <Text mt={-1} small textAlign="right" white>
          {ROLE_NAMES[me?.role] || ''}
        </Text>
      </Column>
      <Dropdown
        items={[
          {
            type: Dropdown.ItemType.Link,
            Icon: FiLogOut,
            content: 'Sign Out',
            to: SIGN_OUT,
          },
        ]}
        offset={[0, 0]}
        placement="bottom"
        withArrow
      >
        <Flex isPointer>
          <Avatar size={44} src={me?.profile?.photoUrl} />
        </Flex>
      </Dropdown>
    </Container>
  )
}

export default Header
