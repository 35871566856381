import React, { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useRouteMatch } from 'react-router'
import { renderRoutes } from 'react-router-config'
import { ToastContainer } from 'react-toastify'

import { FiX } from 'react-icons/fi'

import { ThemeProvider as MUIThemeProvider } from '@mui/system'
import { useStoreon } from 'storeon/react'

import { APP_NAME } from 'Config'

import { APP_KIND } from 'Constants/app'
import { USER_ROLE } from 'Constants/ids'
import { AUTH_STATE, I18N_STATE } from 'Constants/store'

import meQuery from 'GraphQL/Queries/User/me.graphql'

import { useAppTheme } from 'Hooks'

import router from 'Router'
import { PORTAL_ROOT } from 'Router/routes'

import { useQuery } from 'Services/Apollo'
import AppContext from 'Services/AppContext'
import { LocaleRenderer } from 'Services/I18n'
import { useAppKind } from 'Services/Store/appKind'
import { SubscriptionManager } from 'Services/SubscriptionManager'

import { GlobalStyle, ThemeProvider } from 'Theme'

import { Loader } from './Components/UI'

export default function App() {
  const theme = useAppTheme()
  const location = useLocation()
  const matchPortalRoute = useRouteMatch({
    path: PORTAL_ROOT,
  })

  const { appKind, setAppKind } = useAppKind()
  const { auth, i18n } = useStoreon(AUTH_STATE, I18N_STATE)

  const locale = i18n?.locale

  const { data, loading, refetch } = useQuery(meQuery, {
    skip: !auth?.accessToken,
    fetchPolicy: 'network-only',
  })

  const me = data?.me

  const routes = useMemo(() => router(me), [me])

  useEffect(() => {
    // TODO: NOT the best solution;
    // rewrite to switch/case (?) for future new kinds
    const appKindByPathname = matchPortalRoute
      ? APP_KIND.ADVOCATE
      : APP_KIND.ADMIN

    if (appKind === appKindByPathname) return

    setAppKind(appKindByPathname)
  }, [matchPortalRoute, location, appKind, setAppKind])

  useEffect(() => {
    if (!auth?.accessToken) return

    refetch().then()
  }, [refetch, auth])

  if (!me && loading) {
    return <Loader fullScreen text="Loading..." />
  }

  const isAdmin = me?.role === USER_ROLE.ADMIN
  const isSuperAdmin = me?.role === USER_ROLE.SUPER_ADMIN

  return (
    <ThemeProvider theme={theme}>
      <MUIThemeProvider theme={theme?.muiTheme}>
        <GlobalStyle />

        <LocaleRenderer key={locale}>
          <AppContext.Provider value={{ me, locale, isSuperAdmin, isAdmin }}>
            <Helmet>
              <title>{APP_NAME}</title>
              {theme.webfonts.map(font => (
                <link
                  href={`https://fonts.googleapis.com/css?family=${font}`}
                  key={font}
                  rel="stylesheet"
                />
              ))}
              <link
                href="https://unpkg.com/react-day-picker/lib/style.css"
                rel="stylesheet"
              />
            </Helmet>

            {renderRoutes(routes)}

            <ToastContainer
              className="toast-container"
              closeButton={() => <FiX size={10} />}
              progressClassName="toast-progress"
              toastClassName="toast"
            />
            <SubscriptionManager />
          </AppContext.Provider>
        </LocaleRenderer>
      </MUIThemeProvider>
    </ThemeProvider>
  )
}
