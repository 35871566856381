import { useCallback, useMemo } from 'react'

import { useQuery } from '@apollo/client'

import { MAX_LIMIT } from 'Constants/query'

import moreMessagesQuery from 'GraphQL/Queries/Chat/moreMessages.graphql'

function useChatMessages({ channelId, limit }) {
  const messagesQuery = useQuery(moreMessagesQuery, {
    variables: { channelId, first: limit ?? MAX_LIMIT },
    skip: !channelId,
    context: { chat: true },
    fetchPolicy: 'cache-and-network',
  })

  const fetchMore = useCallback(() => {
    if (messagesQuery.loading || !messagesQuery.data?.moreMessages?.lastCursor)
      return

    messagesQuery.fetchMore({
      variables: {
        channelId,
        after: messagesQuery.data.moreMessages.lastCursor,
        first: MAX_LIMIT,
      },
    })
  }, [channelId, messagesQuery])

  return useMemo(
    () => ({
      fetchMore,
      messages: messagesQuery.data?.moreMessages?.messages,
      loading: messagesQuery.loading,
    }),
    [fetchMore, messagesQuery],
  )
}

export { useChatMessages }
