import styled, { css } from 'styled-components'
import { margin } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { either, when } from 'Theme/StyledSystem'

export const Container = styled.label.attrs(props => ({
  width: props.width,
  height: props.height,
}))`
  --width: ${props => props.width}px;
  --height: ${props => props.height}px;
  --radius: var(--height);
  --toggle-size: calc(var(--height) / 1.5);
  --offset: calc(var(--height) / 5);

  display: inline-block;
  position: relative;
  cursor: ${either('disabled', 'not-allowed', 'pointer')};
  width: var(--width);
  height: var(--height);

  ${margin}
`

export const StyledSwitch = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: var(--width);
  height: var(--height);
  background-color: ${either(
    'disabled',
    themeGet('switches.bg.disabled'),
    themeGet('switches.bg.default'),
  )};
  border-radius: var(--radius);
  border-width: 1px;
  border-style: solid;

  border-color: ${either(
    'disabled',
    themeGet('switches.border.disabled'),
    themeGet('switches.border.default'),
  )};

  transition: all 0.17s ease-in-out;

  &:after {
    position: absolute;
    content: '';
    top: 50%;
    transform: translate(var(--offset), -50%);
    width: var(--toggle-size);
    height: var(--toggle-size);
    background-color: ${either(
      'disabled',
      themeGet('switches.icon.bg.disabled'),
      themeGet('switches.icon.bg.default'),
    )};

    border-radius: 50%;

    transition: all 0.17s ease-in-out;
  }
`

export const HiddenSwitch = styled.input.attrs({
  type: 'checkbox',
})`
  opacity: 0;
  width: var(--width);
  height: var(--height);

  &:checked ~ ${StyledSwitch} {
    background-color: ${either(
      'disabled',
      themeGet('switches.bg.checkedDisabled'),
      themeGet('switches.bg.checked'),
    )};

    border-color: ${either(
      'disabled',
      themeGet('switches.border.checkedDisabled'),
      themeGet('switches.border.checked'),
    )};

    &:after {
      --transform: calc(var(--width) - var(--toggle-size) - var(--offset));
      transform: translate(var(--transform), -50%);
      background-color: ${either(
        'disabled',
        themeGet('switches.icon.bg.checkedDisabled'),
        themeGet('switches.icon.bg.checked'),
      )};
    }
  }

  ${when(
    'disabled',
    css`
      pointer-events: none;
    `,
  )};
`
