import palette from './palette'

const buttons = {
  height: {
    big: 40,
    small: 24,
    default: 32,
  },
  fontSize: {
    small: 12,
    default: 14,
  },
  fontWeight: {
    small: 'normal',
    default: 'normal',
  },
  padding: {
    big: `0 32px`,
    small: `0 16px`,
    default: `0 24px`,
  },
  icon: {
    size: {
      big: 26,
      small: 14,
      default: 22,
    },
  },
  border: {
    radius: {
      squared: 10,
      default: 24,
    },
    width: { default: 1 },
    color: {
      negative: palette.negative,
      positive: palette.positive,
      secondary: palette.grayLight,
      tertiary: palette.smokeLightest,
      default: palette.primary,
    },
  },
  color: {
    secondary: palette.grayDark,
    default: palette.white,
  },
  backgroundColor: {
    negative: palette.negative,
    positive: palette.positive,
    secondary: palette.white,
    tertiary: palette.smokeLightest,
    default: palette.primary,
  },
  active: {
    border: {
      color: {
        negative: palette.negativeDark,
        positive: palette.positiveDark,
        secondary: palette.grayDark,
        default: palette.primaryDark,
      },
    },
    color: { default: palette.white },
    backgroundColor: {
      negative: palette.negativeDark,
      positive: palette.positiveDark,
      secondary: palette.grayDark,
      default: palette.primary,
    },
    shadow: { default: 'none' },
  },
  hover: {
    border: {
      color: {
        negative: palette.negativeDark,
        positive: palette.positiveDark,
        secondary: palette.grayDark,
        default: palette.primaryDark,
      },
    },
    color: {
      secondary: palette.grayDark,
      default: palette.white,
    },
    backgroundColor: {
      negative: palette.negativeDark,
      positive: palette.positiveDark,
      secondary: palette.white,
      default: palette.primaryDark,
    },
    shadow: { default: '8px 8px 32px -12px rgba(70, 70, 70, 0.85)' },
  },
  outline: {
    color: {
      negative: palette.negativeDark,
      positive: palette.positiveDark,
      default: palette.primaryDark,
    },
    border: {
      negative: palette.negativeLight,
      positive: palette.positiveLight,
      default: palette.primaryLight,
    },
    active: {
      color: {
        negative: palette.negativeDark,
        positive: palette.positiveDark,
        default: palette.primaryDark,
      },
      border: {
        negative: palette.negative,
        positive: palette.positive,
        default: palette.primary,
      },
    },
    hover: {
      color: {
        negative: palette.negativeDark,
        positive: palette.positiveDark,
        default: palette.primaryDark,
      },
      border: {
        negative: palette.negativeDark,
        positive: palette.positiveDark,
        default: palette.primaryDark,
      },
    },
    disabled: {
      color: { default: palette.gray },
      border: { default: palette.grayLight },
    },
  },
  disabled: {
    border: {
      color: { default: palette.grayLight },
    },
    backgroundColor: {
      secondary: palette.white,
      default: palette.grayLight,
    },
    color: { default: palette.gray },
  },
}

export default buttons
