import React from 'react'

import UserDropdown from 'Components/Blocks/UserDropdown'
import { Link, Logo, Row } from 'Components/UI'

import { APP_NAME } from 'Config'

import { APP_ROOT } from 'Router/routes'

import { AppName, Container } from './styles'

function Header() {
  return (
    <Container center height={48} noShrink px={4} spaceBetween>
      <Row center>
        <Link clean to={APP_ROOT}>
          <Logo size={32} />
          <AppName ml={2}>{APP_NAME}</AppName>
        </Link>
      </Row>

      <UserDropdown />
    </Container>
  )
}

export default Header
