import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled.div.attrs({
  as: 'section',
})`
  display: flex;
  flex-direction: column;
  background: rgb(238, 198, 198);
  background: linear-gradient(
    180deg,
    rgba(238, 198, 198, 1) 0%,
    rgba(218, 163, 189, 1) 100%
  );
  width: 100vw;
  height: 100vh;
  padding: 0 ${themeGet('space.3')}px;
  overflow-y: auto;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
  background-color: ${themeGet('colors.white')};
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
`

export const PageContent = styled.div`
  overflow: hidden;
`
