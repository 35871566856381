import palette from './palette'

const COLORS = {
  DEFAULT: palette.gray,
  CHECKED: palette.primary,
  DISABLED: palette.grayLight,
  CHECKED_DISABLED: palette.primaryLight,
}

const switches = {
  border: {
    default: COLORS.DEFAULT,
    checked: COLORS.CHECKED,
    disabled: COLORS.DISABLED,
    checkedDisabled: COLORS.CHECKED_DISABLED,
  },
  bg: {
    default: palette.white,
    disabled: palette.white,
    checked: palette.white,
    checkedDisabled: palette.white,
  },
  icon: {
    bg: {
      default: COLORS.DEFAULT,
      checked: COLORS.CHECKED,
      disabled: COLORS.DISABLED,
      checkedDisabled: COLORS.CHECKED_DISABLED,
    },
  },
}

export default switches
