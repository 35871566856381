import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import { Button, Modal, Row } from 'Components/UI'
import Input from 'Components/UI/Forms/Input'

import { DELETE_CONFIRMATION_STRING } from 'Constants/values'

import { useModal } from 'Hooks'

import _ from 'Services/I18n'

import { Content } from './styles'

function DialogModal(props) {
  const {
    cancelText,
    confirmText,
    disabled,
    content,
    onFinish,
    withTypedConfirmation,
  } = props

  const [modalProps] = useModal(props)

  const [deleteConfirmation, setDeleteConfirmation] = useState('')

  const handleClose = modalProps.onClose

  const handleChangeConfirmation = useCallback(event => {
    const { value } = event.target
    setDeleteConfirmation(value)
  }, [])

  const handleFinish = useCallback(
    async success => {
      await onFinish(success)
      await handleClose(success)
      setDeleteConfirmation('')
    },
    [handleClose, onFinish],
  )

  const handleConfirm = useCallback(() => {
    if (
      withTypedConfirmation &&
      deleteConfirmation !== DELETE_CONFIRMATION_STRING
    )
      return
    handleFinish(true)
  }, [deleteConfirmation, handleFinish, withTypedConfirmation])

  const handleCancel = useCallback(() => {
    handleFinish(false)
    setDeleteConfirmation('')
  }, [handleFinish])

  return (
    <Modal {...modalProps}>
      <Content alignCenter={withTypedConfirmation}>
        {content || _('modal.dialog.content')}
      </Content>
      {withTypedConfirmation && (
        <Input
          mb={5}
          px={4}
          value={deleteConfirmation}
          onChange={handleChangeConfirmation}
        />
      )}
      <Row alignCenter mb={4} px={4} spaceBetween>
        <Button flex={1} mr={2} secondary onClick={handleCancel}>
          {cancelText || _('modal.dialog.cancel')}
        </Button>

        <Button
          disabled={
            disabled ||
            (withTypedConfirmation &&
              deleteConfirmation !== DELETE_CONFIRMATION_STRING)
          }
          flex={1}
          ml={2}
          onClick={handleConfirm}
        >
          {confirmText || _('modal.dialog.confirm')}
        </Button>
      </Row>
    </Modal>
  )
}

DialogModal.defaultProps = {
  cancelText: null,
  confirmText: null,
  content: null,
  disabled: false,
  withTypedConfirmation: false,
  onFinish: noop,
}

DialogModal.propTypes = {
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  content: PropTypes.node,
  disabled: PropTypes.bool,
  withTypedConfirmation: PropTypes.bool,
  onFinish: PropTypes.func,
}

export default DialogModal
