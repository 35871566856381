import React, { useMemo } from 'react'
import { useRouteMatch } from 'react-router'

import { Link } from 'Components/UI'

import { PORTAL_ROOT, ROOT } from 'Router/routes'

import { Code, Container } from './styles'

export default function NotFound() {
  const matchPortalRoute = useRouteMatch({
    path: PORTAL_ROOT,
  })

  const linkTo = useMemo(
    () => (matchPortalRoute ? PORTAL_ROOT : ROOT),
    [matchPortalRoute],
  )

  return (
    <Container>
      <Code>404</Code>
      Page not found
      <Link to={linkTo}>Go to the root</Link>
    </Container>
  )
}
