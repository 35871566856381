import loadable from '@loadable/component'

const App = {
  Root: loadable(() => import('./Root' /* webpackChunkName: "app-root" */)),
  Profile: loadable(() =>
    import('./Profile' /* webpackChunkName: "app-profile" */),
  ),
}

export default App
