import * as AbsintheSocket from '@absinthe/socket'
import { ApolloLink } from '@apollo/client'
import { Socket as PhoenixSocket } from 'phoenix'

import first from 'lodash/first'

import { getAccessToken } from 'Services/Store/auth'

import createAbsintheSocketLink from './absintheSocketLink'

const MAX_CONNECTION_RETIES = 3
export default class ChatSubscriptionLink extends ApolloLink {
  socket

  token

  url

  connectionRetry = 0

  constructor(path) {
    super()

    this.token = getAccessToken()

    if (this.token) {
      this.createSubscriptionClient(path)
    }
  }

  request(operation, forward) {
    if (!this.url) return null

    return this.url.request(operation, forward)
  }

  createSubscriptionClient(path) {
    const phoenixSocket = new PhoenixSocket(path, {
      params: () => ({ token: this.token }),
      reconnectAfterMs: () => 5000,
    })

    this.socket = AbsintheSocket.create(phoenixSocket)

    phoenixSocket.onError(error => {
      // eslint-disable-next-line no-console
      console.log('❗️ createChatSubscriptionClient: error', error)
      if (this.connectionRetry >= MAX_CONNECTION_RETIES) {
        // eslint-disable-next-line no-console
        console.log('❗️ connection retry reached')
        return
      }

      this.connectionRetry += 1
      setTimeout(() => {
        phoenixSocket.connect()
      }, 1000)
    })

    this.url = createAbsintheSocketLink(this.socket)
  }

  onTokenChanged(token) {
    if (token !== this.token) {
      this.token = token

      if (this.socket) {
        this.socket.phoenixSocket.disconnect()

        const firstChannel = first(this.socket.phoenixSocket.channels)
        if (firstChannel) firstChannel.leave()

        if (this.token) {
          this.socket.channel = this.socket.phoenixSocket.channel(
            '__absinthe__:control',
          )
          this.socket.channelJoinCreated = false
          this.socket.phoenixSocket.connect()
        }
      } else if (this.token) {
        this.createSubscriptionClient()
      }
    }
  }
}
