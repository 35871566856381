export const USER_ROLE = {
  SUPER_ADMIN: 'superAdmin',
  ADMIN: 'admin',
  USER: 'user',
}

export const PARTICIPANT_ROLE = {
  GUEST: 'guest',
  ADMIN: 'admin',
  OPERATOR: 'operator',
}

export const BREAKPOINTS = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
  LARGE: 'large',
}

export const LANGUAGE = {
  EN: 'en',
  RU: 'ru',
}

export const UPLOAD_TYPES = {
  profilePhoto: 'profilePhoto',
  organizationLogo: 'organizationLogo',
  organizationIcon: 'organizationIcon',
  industryIcon: 'industryIcon',
  roomImage: 'roomImage',
  pdfDoc: 'default',
}

export const SOCIAL = {
  apple: 'apple',
  google: 'google',
}

export const MISSING_ID_STRING = ':id'
