import { AppLayout } from 'Components/Layout'

import App from 'Pages/App'

import * as ROUTES from './routes'

export const APP_ROUTES = {
  component: AppLayout,
  path: ROUTES.APP_ROOT,
  routes: [
    {
      component: App.Root,
      path: ROUTES.APP_ROOT,
      exact: true,
    },
    {
      component: App.Profile,
      path: ROUTES.APP_PROFILE,
      exact: true,
    },
  ],
}
