function offsetMerge(existing, incoming, page) {
  // eslint-disable-next-line no-underscore-dangle
  const existingRowsSet = new Set(existing?.rows?.map(row => row.__ref)) ?? []
  const incomingRows =
    incoming?.rows?.filter(
      // eslint-disable-next-line no-underscore-dangle
      incomingRow => !existingRowsSet.has(incomingRow.__ref),
    ) ?? []

  return {
    ...incoming,
    rows: page
      ? existing?.rows?.concat(incomingRows)
      : incomingRows.concat(existing?.rows ?? []),
  }
}

function messagesCursorMerge(existing, incoming, after) {
  const existingMessages = existing?.messages ?? []
  // eslint-disable-next-line no-underscore-dangle
  const existingMessagesSet = new Set(
    // eslint-disable-next-line no-underscore-dangle
    existingMessages.map(message => message.__ref),
  )
  const incomingMessages = incoming?.messages?.filter(
    incomingMessage =>
      // eslint-disable-next-line no-underscore-dangle
      !existingMessagesSet.has(incomingMessage.__ref),
  )

  return {
    lastCursor: incoming?.lastCursor,
    messages: after
      ? existingMessages.concat(incomingMessages ?? [])
      : incomingMessages?.concat(existingMessages),
  }
}

export { messagesCursorMerge, offsetMerge }
