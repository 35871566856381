import React from 'react'

import { FiLogOut, FiUser } from 'react-icons/fi'

import Avatar from 'Components/Blocks/Avatar'
import { Column, Dropdown, Flex, Row } from 'Components/UI'

import { APP_KIND } from 'Constants/app'

import { useAppContext } from 'Hooks'

import { APP_PROFILE, PORTAL_PROFILE, SIGN_OUT } from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'
import { useAppKind } from 'Services/Store/appKind'

import { UserName } from './styles'

function UserDropdown() {
  const { me } = useAppContext()
  const { appKind } = useAppKind()

  const s = useScopedI18n('blocks.userDropdown')

  return (
    <Row>
      <UserName alignEnd justifyCenter>
        {me?.profile?.firstName}
        <br />
        {me?.profile?.lastName}
      </UserName>
      <Column>
        <Dropdown
          items={[
            {
              type: Dropdown.ItemType.Link,
              Icon: FiUser,
              content: s('phrase.profile'),
              to: appKind === APP_KIND.ADVOCATE ? PORTAL_PROFILE : APP_PROFILE,
            },
            {
              type: Dropdown.ItemType.Link,
              Icon: FiLogOut,
              content: s('phrase.signOut'),
              to: SIGN_OUT,
            },
          ]}
          offset={[-32, 2]}
          withArrow
        >
          <Flex isPointer>
            <Avatar size={44} src={me?.profile?.avatar?.sizes?.medium?.url} />
          </Flex>
        </Dropdown>
      </Column>
    </Row>
  )
}

export default UserDropdown
