import { persistState } from '@storeon/localstorage'
import { createStoreon } from 'storeon'

import camelCase from 'lodash/camelCase'

import { APP_NAME } from 'Config'

import { APP_KIND_STATE, AUTH_STATE, I18N_STATE } from 'Constants/store'

import { setStore } from 'Services/Shared'

import { appKindModule } from './appKind'
import authState from './auth'
import i18nState from './i18n'

export function createStore() {
  const store = createStoreon([
    authState,
    i18nState,
    appKindModule,
    persistState([AUTH_STATE, I18N_STATE, APP_KIND_STATE], {
      key: camelCase(APP_NAME),
    }),
  ])

  setStore(store)

  return store
}
