import React from 'react'
import PropTypes from 'prop-types'

import { MOBILE_APP_KIND } from 'Constants/app'

import { Container, LogoIcon } from './styles'

function Logo({ inverse, kind, ...rest }) {
  return (
    <Container inverse={inverse} {...rest}>
      <LogoIcon inverse={inverse ? 1 : 0} kind={kind} />
    </Container>
  )
}

Logo.defaultProps = {
  inverse: false,
  size: 48,
  kind: MOBILE_APP_KIND.DC,
}

Logo.propTypes = {
  inverse: PropTypes.bool,
  kind: PropTypes.oneOf([
    MOBILE_APP_KIND.DC,
    MOBILE_APP_KIND.KAPUA,
    MOBILE_APP_KIND.SOS,
    MOBILE_APP_KIND.UW,
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Logo
