import React from 'react'

import ChatChannel from './ChatChannel'
import ChatEmoji from './ChatEmoji'
import ChatMessage from './ChatMessage'
import Room from './Room'
import RoomUpdate from './RoomUpdate'

export function SubscriptionManager() {
  return (
    <>
      <ChatEmoji />
      <ChatChannel />
      <ChatMessage />
      <RoomUpdate />
      <Room />
    </>
  )
}
