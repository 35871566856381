import apiCall from 'Services/Api'

export function uploadFile({ file, type, entityId, onProgress }) {
  const query = entityId ? { type, entityId } : { type }
  return apiCall({
    endpoint: '/private/uploadFile',
    method: 'POST',
    query,
    file,
    onProgress,
  })
}

export function uploadDirectoryContactsCSV({
  file,
  onProgress,
  headquarterId,
}) {
  return apiCall({
    endpoint: `/private/directoryContacts/uploadCsv`,
    method: 'POST',
    query: { headquarterId },
    file,
    onProgress,
  })
}
