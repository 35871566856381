import React from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import PropTypes from 'prop-types'

import { FiCalendar } from 'react-icons/fi'

import styled from 'styled-components'
import styledMap, { mapToTheme } from 'styled-map'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import * as Styles from 'Components/Styles'

import Flex from '../Flex'
import Input from '../Forms/Input'

export const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: ${mapToTheme('inputs.height')}px;
  color: ${themeGet('colors.font.primary')};

  .DayPickerInput {
    flex: 1;
    position: relative;
    z-index: 0;

    pointer-events: ${styledMap`
      disabled: none;
      default: all;
    `};
  }

  cursor: ${styledMap`
    disabled: not-allowed;
    default: default;
  `};

  ${layout}
  ${margin}
`

export const Value = styled.div`
  ${Styles.text()};
  white-space: nowrap;

  ${margin}
`

const PickerInputWrapper = styled(Flex)`
  position: relative;
`

const CalendarIcon = styled(Flex).attrs({
  children: <FiCalendar size={20} />,
})`
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);

  z-index: 1;

  color: ${styledMap`
    disabled: ${themeGet('colors.gray')};
    default: ${themeGet('colors.primary')};
  `};
`

const PickerInputComponent = React.forwardRef((props, ref) => (
  <PickerInputWrapper disabled={props.disabled} width={1}>
    <CalendarIcon disabled={props.disabled} size={20} />
    <Input pl={32} ref={ref} width={1} {...props} />
  </PickerInputWrapper>
))

PickerInputComponent.defaultProps = {
  disabled: false,
}

PickerInputComponent.propTypes = {
  disabled: PropTypes.bool,
}

export const PickerInput = styled(DayPickerInput).attrs({
  overlayComponent: () => null,
  component: PickerInputComponent,
})``
