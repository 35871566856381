import { MOBILE_APP_KIND } from 'Constants/app'
import { MOBILE_TYPES } from 'Constants/types'

import { getEnv } from 'Services/Envs'

export const APP_ENV = getEnv('ENV')

export const IS_PRODUCTION = APP_ENV === 'production'
export const IS_DEVELOPMENT = APP_ENV === 'development'

const API_PROTOCOL = getEnv('API_SSL') === 'true' ? 'https' : 'http'
const SOCKET_PROTOCOL = getEnv('API_SSL') === 'true' ? 'wss' : 'ws'

export const APP_NAME = getEnv('NAME')

const API_URL = `${API_PROTOCOL}://${getEnv('API_URL')}`

export const API = {
  URL: API_URL,
  WS: `${SOCKET_PROTOCOL}://${getEnv('API_URL')}`,
  PROXY: `${API_URL}/proxy/`,
  CHAT: `${API_PROTOCOL}://${getEnv('API_CHAT_URL')}`,
  CHAT_WS: `${SOCKET_PROTOCOL}://${getEnv('API_CHAT_URL')}`,
}

export const SENTRY = {
  DSN: getEnv('SENTRY_DSN'),
}

export const MOBILE_APP = {
  [MOBILE_APP_KIND.DC]: {
    NAME: getEnv('MOBILE_NAME'),
    SHORT_NAME: getEnv('MOBILE_SHORT_NAME'),
    HOST: getEnv('MOBILE_HOST'),
    DOWNLOAD_LINK: {
      [MOBILE_TYPES.iOS]: getEnv('MOBILE_DOWNLOAD_LINK_IOS'),
      [MOBILE_TYPES.android]: getEnv('MOBILE_DOWNLOAD_LINK_ANDROID'),
    },
  },

  [MOBILE_APP_KIND.SOS]: {
    NAME: getEnv('SOS_MOBILE_NAME'),
    SHORT_NAME: getEnv('SOS_MOBILE_SHORT_NAME'),
    HOST: getEnv('SOS_MOBILE_HOST'),
    DOWNLOAD_LINK: {
      [MOBILE_TYPES.iOS]: getEnv('SOS_MOBILE_DOWNLOAD_LINK_IOS'),
      [MOBILE_TYPES.android]: getEnv('SOS_MOBILE_DOWNLOAD_LINK_ANDROID'),
    },
  },

  [MOBILE_APP_KIND.KAPUA]: {
    NAME: getEnv('KAPUA_MOBILE_NAME'),
    SHORT_NAME: getEnv('KAPUA_MOBILE_SHORT_NAME'),
    HOST: getEnv('KAPUA_MOBILE_HOST'),
    DOWNLOAD_LINK: {
      [MOBILE_TYPES.iOS]: getEnv('KAPUA_MOBILE_DOWNLOAD_LINK_IOS'),
      [MOBILE_TYPES.android]: getEnv('KAPUA_MOBILE_DOWNLOAD_LINK_ANDROID'),
    },
  },

  [MOBILE_APP_KIND.UW]: {
    NAME: getEnv('UW_MOBILE_NAME'),
    SHORT_NAME: getEnv('UW_MOBILE_SHORT_NAME'),
    HOST: getEnv('UW_MOBILE_HOST'),
    DOWNLOAD_LINK: {
      [MOBILE_TYPES.iOS]: getEnv('UW_MOBILE_DOWNLOAD_LINK_IOS'),
      [MOBILE_TYPES.android]: getEnv('UW_MOBILE_DOWNLOAD_LINK_ANDROID'),
    },
  },
}
