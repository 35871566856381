import { APP_KIND } from 'Constants/app'

import { PORTAL_ROOT, ROOT } from 'Router/routes'

import { getApolloClient } from 'Services/Shared'
import { clearAuth } from 'Services/Store/auth'

export async function signOut(history, appKind) {
  const apolloClient = getApolloClient()

  history.push(appKind === APP_KIND.ADVOCATE ? PORTAL_ROOT : ROOT)

  clearAuth()

  await apolloClient.resetStore()
}
