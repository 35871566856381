import { ApolloLink, Observable } from '@apollo/client'
import { print } from 'graphql'
import { createClient } from 'graphql-ws'

import map from 'lodash/map'

import { getAccessToken } from 'Services/Store/auth'

export default class MainSubscriptionLink extends ApolloLink {
  client

  token

  socket

  constructor(url) {
    super()

    this.token = getAccessToken()
    this.client = createClient({
      url,
      on: {
        connected: socket => {
          this.socket = socket
        },
        closed: () => {
          this.socket = null
        },
      },
      connectionParams: () => {
        const token = getAccessToken()
        return token ? { Authorization: `Bearer ${token}` } : {}
      },
    })
  }

  request(operation) {
    return new Observable(sink =>
      this.client.subscribe(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: error => {
            if (error instanceof Error) {
              sink.error(error)
            } else {
              sink.error(new Error(map(error, err => err?.message).join(', ')))
            }
          },
        },
      ),
    )
  }

  onTokenChanged(token) {
    if (this.socket && this.token !== token) {
      this.token = token
      this.socket.close(4205, 'Client Restart')
    }
  }
}
