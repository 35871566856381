import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import roomChannelQuery from 'GraphQL/Queries/Chat/roomChannel.graphql'

function useRoomChannel({ variables, fetchPolicy }) {
  const roomChannel = useQuery(roomChannelQuery, {
    variables,
    skip: !variables?.roomId,
    context: { chat: true },
    fetchPolicy,
  })

  return useMemo(
    () => ({
      loading: roomChannel.loading,
      refetch: roomChannel.refetch,
      channel: roomChannel.data?.roomChannel,
    }),
    [roomChannel],
  )
}

export default useRoomChannel
