import { FiArrowDown, FiArrowUp } from 'react-icons/fi'

import styled, { css } from 'styled-components'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { text } from 'Components/Styles'

const ARROW_SIZE = 12

export const StyledTable = styled.div`
  width: 100%;

  ${margin}
`

const cellRightCss = ({ right }) =>
  right &&
  css`
    justify-content: flex-end;
  `

const headerContentOffsetCss = ({ contentOffset }) =>
  contentOffset &&
  css`
    margin-bottom: ${contentOffset}px;
  `

export const Header = styled.div`
  display: flex;
  align-items: center;

  ${headerContentOffsetCss}
`

export const HeaderCell = styled.div`
  display: flex;
  align-items: center;
  ${text()};
  font-weight: 700;
  user-select: none;

  ${cellRightCss}
`

export const TableRow = styled.div`
  display: flex;
  align-items: center;

  ${layout.height}
`

export const RowCell = styled.div`
  display: flex;
  align-items: center;

  ${cellRightCss}
`

const bodyRowGapCss = ({ rowGap }) =>
  rowGap &&
  css`
    > ${TableRow}:not(:first-child) {
      margin-top: ${rowGap}px;
    }
  `

export const Body = styled.div`
  ${bodyRowGapCss}
`

export const ArrowAsc = styled(FiArrowUp).attrs({
  size: ARROW_SIZE,
})`
  color: ${themeGet('colors.positive')};

  ${margin}
`

export const ArrowDesc = styled(FiArrowDown).attrs({
  size: ARROW_SIZE,
})`
  color: ${themeGet('colors.negative')};

  ${margin}
`
