import styled, { css } from 'styled-components'
import { layout } from '@styled-system/layout'
import { margin, space } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

export const Wrapper = styled.div`
  ${space}
  ${layout}
`

const labelErrorCss = ({ error }) =>
  error &&
  css`
    color: ${themeGet('colors.negative')};
  `

export const Label = styled.label`
  display: flex;
  align-items: center;
  min-height: 15px;
  font-size: 12px;
  line-height: normal;
  color: ${themeGet('colors.font.secondary')};

  ${margin}
  ${labelErrorCss}
`

export const ErrorWrapper = styled.div`
  color: ${themeGet('colors.negative')};
  font-size: 12px;
  padding: 0 12px;
`
