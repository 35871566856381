import React, { useState } from 'react'
import PropTypes from 'prop-types'

import map from 'lodash/map'

import { ItemButton, ItemLink, Items } from './styles'

import Popover from '../Popover'

const ItemType = {
  Link: 'link',
  Button: 'button',
}

function Dropdown({ items, children, trigger, offset, ...rest }) {
  const [tippy, setTippy] = useState()

  if (items.length === 0) return null

  return (
    <Popover
      clear
      content={
        <Items>
          {map(items, item => {
            if (item.type === ItemType.Link)
              return (
                <ItemLink key={item.content} to={item.to} onClick={tippy?.hide}>
                  {item.Icon && <item.Icon />} {item.content}
                </ItemLink>
              )

            if (item.type === ItemType.Button)
              return (
                <ItemButton
                  key={item.content}
                  onClick={() => {
                    item.action(item.data)
                    tippy?.hide()
                  }}
                >
                  {item.Icon && <item.Icon />} {item.content}
                </ItemButton>
              )

            return null
          })}
        </Items>
      }
      hideOnClick
      interactive
      offset={offset}
      trigger={trigger}
      onCreate={setTippy}
      {...rest}
    >
      {children}
    </Popover>
  )
}

Dropdown.defaultProps = {
  children: null,
  items: [],
  offset: [0, 0],
  trigger: 'click',
}

Dropdown.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  items: PropTypes.arrayOf(PropTypes.object),
  offset: PropTypes.arrayOf(PropTypes.number),
  trigger: PropTypes.oneOf(['click', 'mouseenter focus']),
}

Dropdown.ItemType = ItemType

export default Dropdown
