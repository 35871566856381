import React from 'react'
import { hydrate, render } from 'react-dom'

import { loadableReady } from '@loadable/component'

import Container from './Container'

const root = document.getElementById('root')

if (root.hasChildNodes() === true) {
  loadableReady(() => hydrate(<Container />, root))
} else {
  render(<Container />, root)
}
