import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'

import {
  FiEdit3,
  FiRefreshCcw,
  FiTrash,
  FiTrash2,
  FiUpload,
  FiUploadCloud,
} from 'react-icons/fi'

import { pick } from '@styled-system/props'

import { Dialog } from 'Components/Blocks/Modals'
import { Text } from 'Components/UI'
import Button from 'Components/UI/Button'

import { UPLOAD_TYPES } from 'Constants/ids'

import { uploadFile } from 'Services/Api/Queries/upload'
import _, { useScopedI18n } from 'Services/I18n'

import { fileToDataURL } from 'Utils/Files'

import {
  ButtonsRow,
  Container,
  DeleteButton,
  Image,
  Progress,
  Replace,
  Wrapper,
} from './styles'

function ImageInput({
  alternate,
  secondary,
  disabled,
  entityId,
  hideText,
  hideDelete,
  uploadType,
  value,
  onChange,
  ...rest
}) {
  const s = useScopedI18n('dropzone')

  const [uploadingFile, setUploadingFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [percent, setPercent] = useState(0)
  const [deleteImageModal, setDeleteImageModal] = useState({
    isOpen: false,
    url: null,
  })

  const handleProgress = useCallback(event => setPercent(event?.percent), [])

  const handleUploadFile = useCallback(
    async file =>
      uploadFile({
        file,
        type: uploadType,
        entityId,
        onProgress: handleProgress,
      }),
    [entityId, uploadType, handleProgress],
  )

  const handleDrop = useCallback(
    async ([acceptedFile]) => {
      if (!acceptedFile) return

      setLoading(true)

      fileToDataURL(acceptedFile, setUploadingFile)

      const response = await handleUploadFile(acceptedFile)

      if (response.ok) {
        onChange(response?.fileResource)
      }

      setLoading(false)
      setPercent(0)
    },
    [setUploadingFile, handleUploadFile, onChange],
  )

  const handleCloseDeleteModal = useCallback(() => {
    setDeleteImageModal({ isOpen: false, url: null })
  }, [])

  const handleShowDeleteModal = useCallback(
    file => {
      if (disabled) return
      setDeleteImageModal({ isOpen: true, url: file.url })
    },
    [disabled],
  )

  const handleDeleteImage = useCallback(
    ok => {
      if (ok) {
        setUploadingFile(null)
        onChange(null)
      }
    },
    [onChange],
  )

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: handleDrop,
    accept: 'image/jpeg, image/png, image/jpg',
    disabled: loading || disabled,
    noClick: secondary && uploadingFile,
  })

  const src = uploadingFile ?? value?.content

  return (
    <Container {...pick(rest)}>
      <Wrapper
        alternate={alternate}
        disabled={disabled}
        {...getRootProps()}
        isDragActive={isDragActive}
      >
        <input {...getInputProps()} />

        {src ? (
          <>
            <Image secondary={secondary} src={src} />
            {secondary && !disabled && (
              <ButtonsRow mt={5}>
                <Button icon small onClick={open}>
                  <FiEdit3 />
                </Button>
                <Button icon small onClick={handleShowDeleteModal}>
                  <FiTrash />
                </Button>
              </ButtonsRow>
            )}
            {loading && (
              <Progress
                secondary={secondary}
                value={percent}
                width={rest.width}
              />
            )}
          </>
        ) : (
          <>
            {!alternate ? (
              <FiUpload size={48} strokeWidth={1} />
            ) : (
              <FiUploadCloud size={30} strokeWidth={1} />
            )}
            {!hideText && !disabled && (
              <>
                <Text light={!alternate} mt={3} small>
                  {s('dragNDrop')} {_('general.orLower')}
                </Text>
                <Text mt={2} primary small>
                  {s('browse')}
                </Text>
              </>
            )}
          </>
        )}
      </Wrapper>

      {src && !loading && !secondary && !disabled && (
        <>
          {!hideDelete && (
            <DeleteButton onClick={handleShowDeleteModal}>
              <FiTrash2 />
            </DeleteButton>
          )}
          <Replace onClick={open}>
            <FiRefreshCcw size={42} strokeWidth={1} />
          </Replace>
        </>
      )}

      <Dialog
        content="Are you sure you want to delete image?"
        isOpen={deleteImageModal.isOpen}
        title="Remove image?"
        onClose={handleCloseDeleteModal}
        onFinish={handleDeleteImage}
      />
    </Container>
  )
}

ImageInput.defaultProps = {
  alternate: false,
  disabled: false,
  entityId: null,
  hideDelete: false,
  height: 200,
  hideText: false,
  secondary: false,
  src: null,
  width: 200,
}

ImageInput.propTypes = {
  alternate: PropTypes.bool,
  disabled: PropTypes.bool,
  entityId: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hideDelete: PropTypes.bool,
  hideText: PropTypes.bool,
  secondary: PropTypes.bool,
  src: PropTypes.string,
  uploadType: PropTypes.oneOf(Object.values(UPLOAD_TYPES)).isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
}

export default ImageInput
