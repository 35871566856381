import styled from 'styled-components'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { flexCenter } from 'Components/Styles'

export const Wrapper = styled.div`
  ${flexCenter};
  padding: ${themeGet('space.1')}px;
  border-radius: 50%;
  background-color: ${themeGet('colors.bg.primaryLight')};
  cursor: pointer;
  color: ${themeGet('colors.white')};

  ${layout.size}
  ${margin}
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
`

export const Empty = styled.div`
  ${flexCenter};
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 1px solid ${themeGet('colors.white')};
`
