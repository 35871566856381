import React from 'react'
import PropTypes from 'prop-types'

import { HiUser } from 'react-icons/hi'

import { pick } from '@styled-system/props'

import { Empty, Image, Wrapper } from './styles'

function Avatar({ size, src, ...rest }) {
  return (
    <Wrapper size={size} {...pick(rest)}>
      {src ? (
        <Image src={src} />
      ) : (
        <Empty>
          <HiUser color="grey" size={size * 0.55} strokeWidth={1} />
        </Empty>
      )}
    </Wrapper>
  )
}

Avatar.defaultProps = {
  size: 32,
  src: null,
}

Avatar.propTypes = {
  size: PropTypes.number,
  src: PropTypes.string,
}

export default Avatar
