import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'
import { themeGet } from '@styled-system/theme-get'

import { toasts } from './styles'

import 'react-toastify/dist/ReactToastify.min.css'
import 'react-image-gallery/styles/css/image-gallery.css'

export default createGlobalStyle`
  ${styledNormalize}

  html {
    height: 100%;
    width: 100%;
    overflow: hidden;
   }

  body {
    color: ${themeGet('colors.font.primary')};
    background-color: ${themeGet('colors.bg.primary')};
    font-size: 14px;
    font-family: ${themeGet('font')};
    line-height: 20px;
    min-width: 100vw;
    min-height: 100vh;
    height: 100%;
    padding: 0;
    overflow: auto;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .image-gallery-thumbnail.active {
    border-color: ${themeGet('colors.primary')};
  } 
  
  .image-gallery-thumbnail:hover {
    border-color: ${themeGet('colors.primary')};
  }

  .image-gallery-icon:hover {
    color: ${themeGet('colors.primary')};
  }

  .image-gallery-bullets .image-gallery-bullet.active:hover {
    background: ${themeGet('colors.primary')};
    border-color: ${themeGet('colors.primary')};
  }

  .image-gallery-bullets .image-gallery-bullet:hover {
    background: ${themeGet('colors.primary')};
    border-color: ${themeGet('colors.primary')};
  }

  ${toasts}
`
